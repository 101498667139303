import React, { useState } from 'react';
import { IoSearch } from "react-icons/io5";
import { PiPaperclipThin } from "react-icons/pi";
import { MdCall } from "react-icons/md";
import { IoMdVideocam } from "react-icons/io";
import { FaCircleInfo } from "react-icons/fa6";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BsPaperclip } from "react-icons/bs";
import { MdOutlineEmojiEmotions } from "react-icons/md";
import { FaDownload } from 'react-icons/fa';
import { IoMdImage } from "react-icons/io";
import { IoDocumentText } from "react-icons/io5";
import { FiMonitor } from "react-icons/fi";
import { CiChat1 } from "react-icons/ci";
import { GoArrowRight } from "react-icons/go";
import { FaBookmark } from "react-icons/fa6";
import { MdUpload } from "react-icons/md";
import { HiOutlinePencil } from "react-icons/hi2";
import { TbWorld } from "react-icons/tb";
import { TbClockHour3Filled } from "react-icons/tb";
import { RxHamburgerMenu } from "react-icons/rx";

const Message = () => {

    const [message, setMessage] = useState("");
    const [isExpanded, setIsExpanded] = useState(false);

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendClick = () => {
    console.log("Message sent:", message);
    setMessage(""); 
  };

  const burgerBtnClick =()=>{
    const pdfDetail = document.querySelector('.pdfDetail');
    if (pdfDetail) {
        pdfDetail.style.display = pdfDetail.style.display === 'none' ? 'block' : 'none';
    }
    setIsExpanded(!isExpanded);
  }


  return (
    <div className='row userHomeBg auto-container'>
        <div className='col-6 col-sm-3 col-md-3 col-lg-3 colSpace'>
            <div className="content">
            <div className='profileEdit'>
                <img src="/assets/images/userProfile.png"/>
                <HiOutlinePencil className='profileEditIcon'/>
            </div>
            <div className='userName'>
                <h4>Satvik Brown</h4>
                <p>Los Angeies, CA 90036</p>
            </div>
            <div>
                <div className='userDetails'>
                    <p>Most Recent Job</p>
                    <h5>Oracle</h5>
                </div>
                <div className='userDetails'>
                    <p>Last Qualification</p>
                    <h5>B.Tech</h5>
                </div>
                <div className='userDetails'>
                    <p>Work Experience</p>
                    <h5>5.5 Years</h5>
                </div>
                <div className='usermail'>
                    <TbWorld className='UserHomeIcon'/>
                    <p>setvik.kk@gmail.com</p>
                </div>
                <div className='usermail'>
                    <TbClockHour3Filled className='UserHomeIcon'/>
                    <p>0123456789</p>
                    <button>Pending</button>
                </div>
                <div className='userResume'>
                    <button>Upload your latest resume</button>
                </div>
            </div>
            </div>
            <div className="content" style={{backgroundColor:'#2d2c4d', color:'white'}}>
                Jobs Applied(0)
            </div>
            <div className="content">
                Saved Jobs(11)
            </div>
            <div className="content">
                Message
            </div>
        </div>
        <div className="col-6 col-sm-9 col-md-9 col-lg-9 messageCont auto-container">
            <div className="col-6 col-sm-3 col-md-3 col-lg-3 messageCol">
                <div className='messageColSearchBar'>
                    <div className="search-box">
                        <IoSearch className="search-icon"/>
                        <input type="text" placeholder="Search Messages" />
                    </div>
                </div>
                <div>
                    <div className='msgUserDetails'>
                        <div className='msgUserDetail msgUserDetailColor'>
                            <div className='msgUser'>
                                <img src="/assets/images/msg1.png" alt="userImg"/>
                                <PiPaperclipThin />
                            </div>
                            <div className='msgUserDetailCont'>
                                <div className='msgUserName'>
                                    <div className='msgUserNameDetail'>
                                        <h6>Annecte Black</h6>
                                        <h5>UI/UX Designer</h5>
                                    </div>
                                    <div>
                                        <p>10.54 PM</p>
                                    </div>
                                </div>
                                <div className='msgUserCont'>
                                    <p>This section of the letter is where you can make a pitch for your candidacy.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='msgUserDetails'>
                        <div className='msgUserDetail'>
                            <div className='msgUser'>
                                <img src="/assets/images/msg2.png" alt="userImg"/>
                            </div>
                            <div className='msgUserDetailCont'>
                                <div className='msgUserName'>
                                    <div className='msgUserNameDetail'>
                                        <h6>Damell Steward</h6>
                                        <h5>Sernior UI Designer</h5>
                                    </div>
                                    <div>
                                        <p>10.54 PM</p>
                                    </div>
                                </div>
                                <div className='msgUserCont'>
                                    <p>This section of the letter is where you can make a pitch for your candidacy.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='msgUserDetails'>
                        <div className='msgUserDetail'>
                            <div className='msgUser'>
                                <img src="/assets/images/msg3.png" alt="userImg"/>
                                <PiPaperclipThin />
                            </div>
                            <div className='msgUserDetailCont'>
                                <div className='msgUserName'>
                                    <div className='msgUserNameDetail'>
                                        <h6>Rober Fox</h6>
                                        <h5>Product Designer</h5>
                                    </div>
                                    <div>
                                        <p>10.54 PM</p>
                                    </div>
                                </div>
                                <div className='msgUserCont'>
                                    <p>This section of the letter is where you can make a pitch for your candidacy.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='msgUserDetails'>
                        <div className='msgUserDetail'>
                            <div className='msgUser'>
                                <img src="/assets/images/msg4.png" alt="userImg"/>
                            </div>
                            <div className='msgUserDetailCont'>
                                <div className='msgUserName'>
                                    <div className='msgUserNameDetail'>
                                        <h6>Jane Cooper</h6>
                                        <h5>Web Designer</h5>
                                    </div>
                                    <div>
                                        <p>10.54 PM</p>
                                    </div>
                                </div>
                                <div className='msgUserCont'>
                                    <p>This section of the letter is where you can make a pitch for your candidacy.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='msgUserDetails'>
                        <div className='msgUserDetail'>
                            <div className='msgUser'>
                                <img src="/assets/images/msg5.png" alt="userImg"/>
                            </div>
                            <div className='msgUserDetailCont'>
                                <div className='msgUserName'>
                                    <div className='msgUserNameDetail'>
                                        <h6>Esther Howard</h6>
                                        <h5>UX Designer</h5>
                                    </div>
                                    <div>
                                        <p>10.54 PM</p>
                                    </div>
                                </div>
                                <div className='msgUserCont'>
                                    <p>This section of the letter is where you can make a pitch for your candidacy.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='msgUserDetails'>
                        <div className='msgUserDetail'>
                            <div className='msgUser'>
                                <img src="/assets/images/msg6.png" alt="userImg"/>
                            </div>
                            <div className='msgUserDetailCont'>
                                <div className='msgUserName'>
                                    <div className='msgUserNameDetail'>
                                        <h6>Wade Warren</h6>
                                        <h5>Product Manager</h5>
                                    </div>
                                    <div>
                                        <p>10.54 PM</p>
                                    </div>
                                </div>
                                <div className='msgUserCont'>
                                    <p>This section of the letter is where you can make a pitch for your candidacy.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='msgUserDetails'>
                        <div className='msgUserDetail'>
                            <div className='msgUser'>
                                <img src="/assets/images/msg2.png" alt="userImg"/>
                            </div>
                            <div className='msgUserDetailCont'>
                                <div className='msgUserName'>
                                    <div className='msgUserNameDetail'>
                                        <h6>Damell Steward</h6>
                                        <h5>Sernior UI Designer</h5>
                                    </div>
                                    <div>
                                        <p>10.54 PM</p>
                                    </div>
                                </div>
                                <div className='msgUserCont'>
                                    <p>This section of the letter is where you can make a pitch for your candidacy.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6 col-sm-9 col-md-9 col-lg-9">
                <div className='userMsgNav'>
                    <div className='userIcon'>
                        <div className='userMsg'>
                            <img src="/assets/images/msg1.png" alt="userImg"/>
                        </div>
                        <div>
                            <h5>Annette Black</h5>
                            <p>Typing...</p>
                        </div>
                    </div>
                    <div className='userMsgIcons'>
                        <div className='userMsgClIcons'>
                            <MdCall className='clIcon'/>
                            <IoMdVideocam className='clIcon'/>
                            <FaCircleInfo className='clIcon'/>
                        </div>
                        <div>
                            <BsThreeDotsVertical style={{color:'blue'}}/>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className={isExpanded ? "col-6 col-sm-12 col-md-12 col-lg-12 userChatContWhole" 
                        : "col-6 col-sm-8 col-md-8 col-lg-8 userChatContWhole"}>
                        <div className='burgerBtnDiv'>
                            <RxHamburgerMenu className='burgerBtn' onClick={burgerBtnClick}/>
                        </div>
                        <div className='userChatCont'>
                            <img src="/assets/images/msg1.png" alt="userImg"/>
                            <div>
                                <div className='userChatContName'>
                                    <h5>Annette Black</h5>
                                    <p>12:33</p>
                                </div>
                                <div className='userChatContPara'>
                                    <p>In this article, I’d like to reacquaint you with the humble workhorse of communication that is the paragraph. Paragraphs are everywhere. In fact, at the high risk of stating the obvious, you are reading one now. Despite their ubiquity, we frequently neglect their presentation. This is a mistake.</p>
                                </div>
                            </div>
                        </div>
                        <div className='userChatCont userChatContSolo'>
                            <img src="/assets/images/userProfile.png" alt="userImg"/>
                            <div className='userChatContIcons'>
                                <div>
                                    <div className='userChatContName'>
                                        <h5>Nasim</h5>
                                        <p>12:34</p>
                                    </div>
                                    <div className='userChatContPara'>
                                        <p>What is the best way to use email to apply for jobs?</p>
                                    </div>
                                </div>
                                <div className={isExpanded ? 'userChatIconsMargin' : 'userChatIcons' } >
                                    <MdOutlineEmojiEmotions />    
                                    <CiChat1 />
                                    <GoArrowRight />
                                    <FaBookmark className='bookmarkIcon'/>
                                    <BsThreeDotsVertical />
                                </div>
                            </div>
                        </div>
                        <div className='userChatCont'>
                            <img src="/assets/images/msg1.png" alt="userImg"/>
                            <div>
                                <div className='userChatContName'>
                                    <h5>Annette Black</h5>
                                    <p>12:34</p>
                                </div>
                                <div className='userChatContPara'>
                                    <p>When you send your resume , it's important to include a concise cover letter.</p>
                                    <img src="/assets/images/screenshot.JPG" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='userChatCont'>
                            <img src="/assets/images/userProfile.png" alt="userImg"/>
                            <div>
                                <div className='userChatContName'>
                                    <h5>Nasim</h5>
                                    <p>12:33</p>
                                </div>
                                <div className='userChatContPara'>
                                    <p>When you send your resume , it's important to include a concise cover letter.</p>
                                </div>
                            </div>
                        </div>
                        <div className='userChatCont'>
                            <img src="/assets/images/msg1.png" alt="userImg"/>
                            <div>
                                <div className='userChatContName'>
                                    <h5>Annette Black</h5>
                                    <p>12:33</p>
                                </div>
                                <div className='userChatContPara'>
                                    <p>In this article, I’d like to reacquaint you with the humble workhorse of communication that is the paragraph. Paragraphs are everywhere. In fact, at the high risk of stating the obvious, you are reading one now. Despite their ubiquity, we frequently neglect their presentation. This is a mistake.</p>
                                </div>
                            </div>
                        </div>
                        <div className='userChatCont'>
                            <img src="/assets/images/userProfile.png" alt="userImg"/>
                            <div>
                                <div className='userChatContName'>
                                    <h5>Nasim</h5>
                                    <p>12:33</p>
                                </div>
                                <div className='userChatContPara'>
                                    <p>In this article, I’d like to reacquaint you with the humble workhorse of communication that is the paragraph. Paragraphs are everywhere. In fact, at the high risk of stating the obvious, you are reading one now. Despite their ubiquity, we frequently neglect their presentation. This is a mistake.</p>
                                </div>
                            </div>
                        </div>
                        <div className='userMsgBtn'>
                            <div className='userChatContTyping'>
                                <img src="/assets/images/msg1.png" alt="userImg"/>
                                <h5>Annette is Typing...</h5>
                            </div>
                            <div className="userMsgInputBox">
                                <textarea
                                    value={message}
                                    onChange={handleInputChange}
                                    placeholder="Message here..."
                                    className="autoResizeTextarea"
                                    rows="2"
                                    cols="200"
                                />
                                <div className="iconContainer">
                                    <BsPaperclip className='userMsgIcon'/>
                                    <MdOutlineEmojiEmotions className='userMsgIcon'/>
                                </div>
                                <button onClick={handleSendClick} className="sendButton">
                                    Send
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-4 col-lg-4 pdfDetail">
                        <div>
                            <div className="userChatFileContainer">
                                <div className="userChatFileDetails">
                                    <div className="userChatIconContainer">
                                        <IoMdImage className="userChatFileIcon"/>
                                        <p className="file-name">Project Management App</p>
                                    </div>
                                    <p className="file-info">JPG • 1.2 MB</p>
                                </div>
                                <div className="userChatDownloadIcon">
                                    <FaDownload />
                                </div>
                            </div>
                            <div className="userChatFileContainer">
                                <div className="userChatFileDetails">
                                    <div className="userChatIconContainer">
                                        <IoDocumentText  className="userChatFileIcon"/>
                                        <p className="file-name">Project Management App</p>
                                    </div>
                                    <p className="file-info">JPG • 1.2 MB</p>
                                </div>
                                <div className="userChatDownloadIcon">
                                    <FaDownload />
                                </div>
                            </div>
                            <div className="userChatFileContainer">
                                <div className="userChatFileDetails">
                                    <div className="userChatIconContainer">
                                        <FiMonitor className="userChatFileIcon"/>
                                        <p className="file-name">Project Management App</p>
                                    </div>
                                    <p className="file-info">JPG • 1.2 MB</p>
                                </div>
                                <div className="userChatDownloadIcon">
                                    <FaDownload />
                                </div>
                            </div>
                            <div className="userChatFileContainer">
                                <div className="userChatFileDetails">
                                    <div className="userChatIconContainer">
                                        <IoMdImage className="userChatFileIcon"/>
                                        <p className="file-name">Project Management App</p>
                                    </div>
                                    <p className="file-info">JPG • 1.2 MB</p>
                                </div>
                                <div className="userChatDownloadIcon">
                                    <FaDownload />
                                </div>
                            </div>
                            
                        </div>
                        <div class="userChatPdfBtn">
                            <button className="userChatUploadBtn">Filter</button>
                            <button className="userChatUploadBtn">
                                <MdUpload className="userChatUploadIcon" />
                                <span>Upload File</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <h1>hi</h1> */}
        </div>
    </div>
  );
};

export default Message;
