import React, { useEffect, useState } from 'react';
import { BsBookmarkDash } from "react-icons/bs";
import { Link, useNavigate } from 'react-router-dom';
import { bookMark, Configuration, getDetails, jobList, jobListTeam, redirect_url } from '../Api/Url';
import axios from 'axios';
import { BsBookmarkDashFill } from "react-icons/bs";
import { useDispatch } from 'react-redux';
import { updateJobSearch } from '../Store/SearchSlice';
import UserHome from './userHome';

const Homepage = ({loginId}) => {

    let viewAllTeamNav = useNavigate();
    let viewAllJobNav = useNavigate();
    const allowUserNav = useNavigate();
    const applyJobNav = useNavigate();
    const config = Configuration();

    const [teamName, setTeamName] = useState([]);
    const [jobLists, setJobLists] = useState('');
    const [bookMarkCont, setBookMarkCont] = useState('');
    const [selectedJobId, setSelectedJobId] = useState(null);
    const [jobSearch, setJobSearch] = useState('')
    const [location, setLocation] = useState('')
    const navJob = useNavigate();
    const searchJobDispatch = useDispatch();
    const nav = useNavigate();

    // const [loginId, setLoginId] = useState('');
    // console.log(loginId);
    
    // useEffect(() => {
    //     const globalValue = window.globalValue;
    //     setLoginId(globalValue);
    //   }, []);
    
    
    useEffect(()=>{
        axios.get(jobList(), config)
        .then(res => setJobLists(res.data.data.values))
        .catch(err => console.log(err));
    }, [])

    useEffect(()=>{
        axios.get(jobListTeam(), config)
        .then(res => {
            setTeamName(res.data.data);
        })
        .catch(err => {
            console.log(err);
        });
    },[])

    const handleIconClick = (id) => {
        let data = {
            "job_id": id
        };

        axios.post(bookMark(), data, config)
            .then(res => {
                setBookMarkCont(res.data.message);
                setSelectedJobId(id); 

                setTimeout(() => {
                    setBookMarkCont('');
                    setSelectedJobId(null); 
                }, 1000);
            })
            .then(() => {
                axios.get(jobList(), config)
                    .then(res => setJobLists(res.data.data.values))
                    .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
    };

    const handleAllowUser = (id)=>{
        if(loginId){
            applyJobNav(`/u/${loginId}/jobdetails?job_id=${id}`);
        }
        else{
            applyJobNav(`/jobdetails?job_id=${id}`);
        }
    }

    const viewAllTeam=()=>{
        if(loginId){
            viewAllTeamNav(`/u/${loginId}/teams`);
        }
        else{
            viewAllTeamNav('/teams')
        }
    }

    const viewAllJob=()=>{
        if(loginId){
            viewAllJobNav(`/u/${loginId}/jobs`);
        }
        else{
            viewAllJobNav('/jobs')
        }
    }

    const handleLogin = () => {
        const globalValue = window.globalValue;
        
        if(globalValue){   
            window.location.href = `https://myaccount.dev.evzone.app/u/${globalValue}/profilechange`;
            
            // window.location.href = `https://myaccount.evzone.app/u/${globalValue}/profilechange=${redirect_url}/home`;
        }
        else{
            const queryParams = new URLSearchParams(window.location.search);
            const query = queryParams.get('job_id');
            let pathname;
            let url;
        
            if (query) {
                pathname = 'userapplication';
            }
            else {
                url = window.location.pathname;
                const segments = url.split('/');
                pathname = segments[1]
            }                      
            window.location.href = `https://accounts.dev.evzone.app/?redirecturl=${redirect_url}${pathname}`;
        }
    };

    const navigateJob = ()=>{
        if(loginId){
            applyJobNav(`/u/${loginId}/jobs`);
        }
        else{
            applyJobNav(`/jobs`);
        }
    }

    const handleSearchJobs=()=>{
        searchJobDispatch(updateJobSearch(jobSearch));
        if(loginId){
            navJob(`/u/${loginId}/jobs`)
        }
        else{
            navJob('/jobs');
        }
    }

    const handleNavLocation=()=>{
        if(loginId){
            navJob(`/u/${loginId}/locations`)
        }
        else{
            navJob('/locations');
        }
    }

    return (
        <>
            {loginId && loginId !== undefined && loginId !== null ? (
                <div>
                    <UserHome loginId={loginId}/>
                </div>
            ) : (
                <div>
                    <section className="banner-section-three at-home22">
                        <div className="auto-container">
                            <div className="row">
                                <div className="content-column col-lg-6 col-md-12 col-sm-12">
                                    <div className="inner-column">
                                        <div className="title-box wow fadeInUp">
                                            <h3>Discover. Apply.<br />
                                                Create Your <span className='g-highlight'>Future</span>
                                            </h3>
                                            <div className="text">Discover countless job opportunities tailored to your skills and  <br />aspirations. Apply with ease and take the first step toward <br />shaping a  successful and fulfilling career.</div>
                                        </div>
                                        <div className="job-search-form">
                                            <form>
                                                <div className="row">
                                                    <div className="form-group col-lg-5 col-md-12 col-sm-12">
                                                        <span className="icon flaticon-search-1"></span>
                                                        <input type="text" value={jobSearch} onChange={(e)=>{setJobSearch(e.target.value)}} placeholder="Job Search" />
                                                    </div>

                                                    <div className="form-group col-lg-4 col-md-12 col-sm-12 location">
                                                        <span className="icon flaticon-map-locator"></span>
                                                        <input type="text" value={location} onChange={(e)=>{setLocation(e.target.value)}} placeholder="Location" />
                                                    </div>

                                                    <div className="form-group col-lg-3 col-md-12 col-sm-12 btn-box">
                                                        {/* <button className="theme-btn btn-style-one green-bg" type="submit"><span className="btn-title">Search</span></button> */}
                                                        <a className="theme-btn btn-style-one green-bg" type="submit" onClick={handleSearchJobs} >Search</a>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="popular-searches mt-30"> <p className="title">Trending Searches</p>
                                            <div className='job-block'>
                                                <ul className="job-other-info at-jsv6 at-jsv17 mt20 ms-0">
                                                    <li className="time mr20">UI Designer</li>
                                                    <li className="time2 mr20">UI Developer</li>
                                                    <li className="time2 mr20">UX UI Designer</li>
                                                    <li className="time2 mr20">Developer</li>
                                                    <li className="time2">Marketing Manager</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="image-column col-lg-6 col-md-12 col-sm-12">
                                    <div className="image-box ms-0 mt50">
                                        <figure className="main-image wow fadeInRight mb-0 align-right"><img src="/assets/images/Job-posting.png" draggable='false' alt="" /></figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="job-categories border-bottom-0 pt-40 dotted-line">
                        <div className="auto-container">
                            <div className="align-items-center justify-content-between wow fadeInUp">
                                <div className="sec-title align-center">
                                    <h2>Land Your Job in <span className='g-highlight'>4 Simple Steps</span></h2>
                                    <div className="text">Navigate your job search effortlessly and land your dream job with our easy four-step process.</div>
                                </div>
                            </div>
                            <div className="row wow fadeInUp">
                                <div className="category-block-two at-home22 col-xl-3 col-sm-6">
                                    <div className="inner-box text-start sm-height" onClick={handleLogin}>
                                        <div className="content justify-content-start d-block"><span><img src="/assets/images/home-icons/create-your-profile.svg" draggable='false' alt="" /></span>
                                            <h4><a>Create Your Profile</a></h4>
                                            <p className="text">Sign up and build a detailed profile highlighting your skills, experience, and career goals.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="category-block-two at-home22 col-xl-3 col-sm-6">
                                    <div className="inner-box text-start sm-height" onClick={navigateJob}>
                                        <div className="content justify-content-start d-block"><span><img src="/assets/images/home-icons/search-for-jobs.svg" draggable='false' alt="" /></span>
                                            <h4><a>Search for Jobs</a></h4>
                                            <p className="text">Use our advanced search filters to find job listings that match your qualifications and preferences.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="category-block-two at-home22 col-xl-3 col-sm-6">
                                    <div className="inner-box text-start sm-height" onClick={navigateJob}>
                                        <div className="content justify-content-start d-block"><span><img src="/assets/images/home-icons/apply-effortlessly.svg" draggable='false' alt="" /></span>
                                            <h4><a>Apply Effortlessly</a></h4>
                                            <p className="text">Submit applications to your chosen jobs with just a few clicks, attaching your resume and cover letter.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="category-block-two at-home22 col-xl-3 col-sm-6">
                                    <div className="inner-box text-start sm-height" onClick={navigateJob}>
                                        <div className="content justify-content-start d-block"><span><img src="/assets/images/home-icons/getjob.svg" draggable='false' alt="" /></span>
                                            <h4><a>Get Job</a></h4>
                                            <p className="text">Find your perfect job quickly and easily with our intuitive job search platform.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="job-section pt-20 light-orange-bg pb-20">
                        <div className="auto-container">
                            <div className="sec-title text-center">
                                <h2>Find your Team</h2>
                                <div className="text">Connect with like-minded professionals and find <br />your perfect team on our job portal.</div>
                            </div>
                            <div className="row">
                                {teamName && teamName.map && teamName.slice(0, 8).map((value, i)=>(
                                    <div className="job-block at-jlv16 col-sm-3">
                                        <div className="inner-box">
                                            <div className="tags d-flex align-items-center"></div>
                                            <div className="content ps-0">
                                                <div className="d-xl-flex align-items-center"> <span className="company-logo position-relative"><img src="/assets/images/home-icons/sales.svg" draggable='false' alt="" /></span>
                                                    <div className="ms-0 ms-xl-3 mt-3 mt-xl-0" key={i}>
                                                        <h6 className="fz20 mb-2 mb-lg-0"><a className='find-team-title'>{value.name}</a></h6>
                                                        <p className="mb-0 fz14 mt10">150 + Job Openings </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='row text-center'>
                                <div className='col-lg-12'><a className="tag tag-btn" onClick={()=>{viewAllTeam()}}>View All Teams</a></div>
                            </div>
                        </div>
                    </section>
                    <section className="job-categories">
                        <div className="auto-container">
                            <div className="text-center mb-4 mb-sm-0">
                                <div className="sec-title">
                                    <h2>Latest and Top <span className='g-highlight'>Job Openings</span></h2>
                                    <div className="text">Discover the latest and top job openings tailored to <br />your skills and career goals.</div>
                                </div>
                            </div>
                            <div className="row wow fadeInUp">
                                {jobLists && jobLists.map && jobLists.slice(0,6).map((value, i)=>(
                                    <div key={i} className="col-xl-4 col-sm-6">
                                        <div className="job-block at-jlv17 at-home20 at-home18">
                                            <div className="inner-box">
                                                <div className="tags">
                                                    <div className='bookMark' onClick={() => handleIconClick(value.id)} style={{ cursor: 'pointer' }}>
                                                                {value.is_bookmark === 0 ? <BsBookmarkDash className="flaticon-bookmark" /> : <BsBookmarkDashFill className="flaticon-bookmark" />}
                                                                {selectedJobId === value.id && <p className='bookMarkCont'>{bookMarkCont}</p>}
                                                    </div>
                                                </div>
                                                <div className="content ps-0">
                                                    <div className="d-flex mb-3 mb-md-0 align-top">
                                                        <span className="position-relative mr15"><img className="wa w60" src="/assets/images/icons/ev-logo-icon.png" draggable='false' alt="" /></span>
                                                        <div className="text-start">
                                                            <h4 className="fz22 mb-2"><a>{value.name}</a></h4>
                                                            <h6 className='font12'>{value.company_detail.name}</h6>
                                                            <ul className="job-other-info at-jsv6 at-jsv17 ms-0">
                                                                <li className="time2 fz12 border-0 ps-0 me-0 mb-0 bg-none"><img className='mr10' src="/assets/images/icons/location.png" draggable='false' alt="" />{value.company_detail.location}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'>
                                                            <p className='text'>{value.description}</p>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-lg-12'>
                                                            <ul className="job-other-info at-jsv6 at-jsv17 mt15 ms-0 justify-content-md-between">
                                                                <li className="time2 border-0 me-0 light-y-bg fz12"><img className='mr10 w-16' src="/assets/images/icons/dark-clock.svg" draggable='false' alt="" />Full Time</li>
                                                                <li className="time2 border-0 light-blue-bg fz12"><img className='mr10 w-16' draggable='false' src="/assets/images/icons/dark-briefcase.svg" alt="" />Remote</li>
                                                                <li className="time2 border-0 bg-D3EFD8 fz12"><img className='mr10 w-16' draggable='false' src="/assets/images/icons/dark-activity-notification-square.svg" alt="" />{value.experience} Years</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='row mt10'>
                                                        <div className='col-lg-12'><span className='mr15 fz14 fw-600'>3 Days ago</span><span><i class="fa fa-circle fz10 mr10" aria-hidden="true"></i></span>
                                                            <span className='text-blue fz14 fw-600'>124 Applicant</span></div>
                                                    </div>
                                                    <div className="align-items-center justify-content-md-between mt10">
                                                        <div className="align-items-center mb-3 mb-md-0">
                                                        {/* <a onClick={()=> handleAllowUser(value.id)} class="theme-btn btn-style-two w-100">
                                                            { value.is_applied === 1 ? 'Application Submitted' : 'Apply Now'}
                                                        </a> */}
                                                            <a
                                                                onClick={value.is_applied !== 1 ? () => handleAllowUser(value.id) : null}
                                                                className={`theme-btn btn-style-two w-100 ${value.is_applied === 1 ? 'disabled' : ''}`}
                                                                style={{
                                                                    pointerEvents: value.is_applied === 1 ? 'none' : 'auto',
                                                                    opacity: value.is_applied === 1 ? 0.6 : 1,
                                                                    backgroundColor: value.is_applied === 1 ? 'gray' : '',  // Set the background color when disabled
                                                                }}
                                                                >
                                                                {value.is_applied === 1 ? 'Application Submitted' : 'Apply Now'}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='row text-center'>
                                <div className='col-lg-12'><a onClick={()=>{viewAllJob()}} className="tag tag-btn green-bg">View All Jobs</a></div>
                            </div>
                        </div>
                    </section>
                    <section className="job-section pt-20 light-orange-bg pb-20">
                        <div className="auto-container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="sec-title">
                                        <h2>Our Locations</h2>
                                        <div className="text">Explore job opportunities across our multiple locations, each offering  unique career prospects. Find the perfect position near you and take the  next step in your career journey.</div>
                                    </div>
                                    <ul className='location-list d-md-flex align-items-center justify-content-md-between'>
                                        <li>
                                            <h4>Kampala</h4>
                                            <p>412 Jobs</p>
                                        </li>
                                        <li>
                                            <h4>Kampala</h4>
                                            <p>412 Jobs</p>
                                        </li>
                                        <li>
                                            <h4>Kampala</h4>
                                            <p>412 Jobs</p>
                                        </li>
                                        <li>
                                            <h4>Kampala</h4>
                                            <p>412 Jobs</p>
                                        </li>
                                    </ul>
                                    <ul className='location-list d-md-flex align-items-center justify-content-md-between mt20'>
                                        <li>
                                            <h4>Kampala</h4>
                                            <p>412 Jobs</p>
                                        </li>
                                        <li>
                                            <h4>Kampala</h4>
                                            <p>412 Jobs</p>
                                        </li>
                                        <li>
                                            <h4>Kampala</h4>
                                            <p>412 Jobs</p>
                                        </li>
                                        <li>
                                            <h4>Kampala</h4>
                                            <p>412 Jobs</p>
                                        </li>
                                    </ul>
                                    <div className='mt30'> <a className="tag tag-btn" onClick={handleNavLocation}>View All Location</a></div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 align-right"><img className="w-75" src="/assets/images/maps.png" draggable='false' alt="" /></div>
                            </div>
                        </div>
                    </section>
                    <section className="job-section pt-20 pb-20">
                        <div className="auto-container">
                            <div className="sec-title text-center">
                                <h2>Perks & Benefits</h2>
                                <div className="text">Enjoy a range of exceptional perks designed to enhance your work-life balance and overall well-being.</div>
                            </div>
                            <div className="row m0">
                                <div className='col-lg-4 col-md-4 col-sm-12 Benefits-box green-bg text-white'>
                                    <h2>01</h2>
                                    <h4 className='mt10'>Unmatched <br />
                                        Benefits
                                    </h4>
                                    <p className='text-white mt20 fz16'>Enjoy comprehensive health coverage, retirement plans, and generous paid time off.</p>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 Benefits-box F9FAFB-bg'>
                                    <h2 className='text-green'>02</h2>
                                    <h4 className='mt10'>Investing in <br />
                                        Your Future
                                    </h4>
                                    <p className='mt20 fz16'>Benefit from continuous learning opportunities, career advancement programs, and professional development support.</p>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 Benefits-box F3F4F6-bg'>
                                    <h2 className='text-green'>03</h2>
                                    <h4 className='mt10'>Work-Life <br />
                                        Harmony
                                    </h4>
                                    <p className='mt20 fz16'>Experience flexible working hours, remote work options, and supportive work-life balance initiatives.</p>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 Benefits-box F9FAFB-bg'>
                                    <h2 className='text-green'>04</h2>
                                    <h4 className='mt10'>Health and <br />
                                        Wellness
                                    </h4>
                                    <p className='mt20 fz16'>Access wellness programs, mental health resources, and on-site fitness facilities to keep you healthy and happy.</p>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 Benefits-box F3F4F6-bg'>
                                    <h2 className='text-green'>05</h2>
                                    <h4 className='mt10'>Innovative <br />
                                        Culture
                                    </h4>
                                    <p className='mt20 fz16'>Collaborate with talented teams, work on cutting-edge projects, and drive meaningful impact.</p>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 Benefits-box F9FAFB-bg'>
                                    <h2 className='text-green'>06</h2>
                                    <h4 className='mt10'>Grow <br />
                                        With Us
                                    </h4>
                                    <p className='mt20 fz16'>Continuous learning, career advancement programs, and mentorship opportunities.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="job-section pt-20 pb-20 mt120">
                        <div className='light-orange-bg mt30'>
                            <div className="auto-container">
                                <div className='row'>
                                    <div className='col-lg-5 update-content'>
                                        <h1>Get Latest Job <br />Updates</h1>
                                        <p className='mt20'>Subscribe to our newsletter for the <br />latest job updates and career <br />opportunities.</p>
                                        <div className="form-group mt20">
                                            <input type="email" name="email" className="email email-input" value="" placeholder="Enter Your Email" required="" />
                                            <button type="button" id="subscribe-newslatters" className="theme-btn btn-style-seven sub-btn orange-bg">Subscribe</button>
                                        </div>
                                    </div>
                                    <div className='col-lg-7'><div className='update-img'><img draggable='false' src="/assets/images/home-person.png" alt="" /></div></div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}
        </>
    );
}
export default Homepage;
