import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BsBookmarkDash } from "react-icons/bs";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { bookMark, Configuration, getDetails, jobApply, jobDetail, jobGet, jobList, redirect_url } from '../Api/Url';
import { BsBookmarkDashFill } from "react-icons/bs";

const JobDetails = () => {

    let config = Configuration();
    const params = useParams();
    const [jobLists, setJobLists] = useState('');
    const [bookmarkedItems, setBookmarkedItems] = useState({});
    const [bookMarkCont, setBookMarkCont] = useState('');
    const [selectedJobId, setSelectedJobId] = useState(null);
    const [allowUser,setAllowUser] = useState('');
    const [applyNowCont, setApplyNowCont] = useState('');
    const [applyBtn, setApplyBtn] = useState('');    
    const [applyJobGet, setApplyJobGet] = useState('');
    const [userApplication, setUserApplication] = useState('');
    const [loading, setLoading] = useState(true)    
    const [applicationAllowUser, setApplicationAllowUser] = useState('')

    useEffect(()=>{
            axios.get(jobList(),config)
            .then(res=>{
                setJobLists(res.data.data.values)
                setLoading(false)
            })
            .catch(err=>{
                console.log(err)
                setLoading(false)
            })       
    },[])

    const location = useLocation();
    let userApplicationNav = useNavigate();

    useEffect(()=>{
        const queryParams = new URLSearchParams(location.search);
        const jobId = queryParams.get('job_id');
        config.params = {job_id: jobId}
        axios.get(jobGet(),config)  // setApplyJobGet
        .then(res=>{
            setApplyJobGet(res.data.data)
            setLoading(false)
        })        
        .catch(err=>{
            console.log(err)
            setLoading(false)
        })
    },[])

    // const handleJobApply=()=>{ 
 
        // const queryParams = new URLSearchParams(location.search);
        // const jobId = queryParams.get('job_id');

        // const data = {
        //     job_id: jobId
        // }  

        // axios.post(jobApply() ,data , config)
        // .then(res=>{

        //     const queryParams = new URLSearchParams(location.search);
        //     const jobId = queryParams.get('job_id');

        //     config.params = {
        //                     job_id: jobId
        //                 }

        //     axios.get(jobGet(), config)
        //     .then(res=>setApplyJobGet(res.data.data))        
        //     .catch(err=>console.log(err))
            
        //      setApplyNowCont(res.data.message)
        //      setTimeout(() => setApplyNowCont(''), 1000);    
        // }) 
        // .catch(err=>console.log(err))
    // }

    // const handleJobApply = () => {
    //     axios.get(getDetails(), config)
    //         .then(res => {                 
    //                 const queryParams = new URLSearchParams(window.location.search);
    //                 const jobId = queryParams.get('job_id');
    
    //                 const data = { job_id: jobId };
    
    //                 axios.post(jobApply(), data, config)
    //                     .then(res => {
    //                         config.params = { job_id: jobId };
    
    //                         axios.get(jobGet(), config)
    //                             .then(res => setApplyJobGet(res.data.data))
    //                             .catch(err => console.log(err));
    
    //                         setApplyNowCont(res.data.message);
    //                         setTimeout(() => setApplyNowCont(''), 1000);
    //                     })
    //                     .catch(err => console.log(err));
    //                 })
    //         .catch(err => {

    //             // const queryParams = new URLSearchParams(window.location.search);
    //             //     const jobId = queryParams.get('job_id');

    //             //     const redirectUrl = `https://myaccount.evzone.app?redirecturl=${encodeURIComponent(redirect_url)}&job_id=${jobId}`;

    //             //     window.location.href = redirectUrl;
    //             //     console.log(redirectUrl);


    //             setAllowUser(err.response?.status);
    //             if (allowUser === 401) {

    //                 const queryParams = new URLSearchParams(window.location.search);
    //                 const jobId = queryParams.get('job_id');
    //                 const data = { job_id: jobId };    
    //                 console.log(data);
                          
                    
    //                 // window.location.href = `https://myaccount.evzone.app?redirecturl=${redirect_url}?job_id=${data}`;
    //             }
    //         });
    // };
      
    const [loginId, setLoginId] = useState('');
    
    useEffect(() => {
      const globalValue = window.globalValue;
      setLoginId(globalValue);
    }, [window.globalValue]);

    const handleJobApply = () => {
        axios.get(getDetails(), config)
            .then(res=>{
                const value = res.data.data
                setApplicationAllowUser(value)  //get_user_job_specify_detail  user_detail
                // if(value.get_user_job_specify_detail == null || value.user_detail == null){
                //     const queryParams = new URLSearchParams(window.location.search);
                //     const jobId = queryParams.get('job_id');
                //     if(loginId){
                //         userApplicationNav(`/u/${loginId}/userapplication?job_id=${jobId}`)
                //     }
                //     else{
                //         userApplicationNav(`/userapplication?job_id=${jobId}`)
                //     }
                // }

                    const queryParams = new URLSearchParams(window.location.search);
                    const jobId = queryParams.get('job_id');
                    if(loginId){
                        userApplicationNav(`/u/${loginId}/userapplication?job_id=${jobId}`)
                    }
                    else{
                        userApplicationNav(`/userapplication?job_id=${jobId}`)
                    }
                    
                    // const queryParams = new URLSearchParams(window.location.search);
                    // const jobId = queryParams.get('job_id');
        
                    const data = { job_id: jobId };

                    axios.post(jobApply(), data, config)
                        .then(res => {
                            config.params = { job_id: jobId };

                            axios.get(jobGet(), config)
                                .then(res => {
                                    const appliedJobData = res.data.data;
                                    setApplyJobGet(appliedJobData);
                                    axios.get(jobList(), config)
                                        .then(res => {
                                            let updatedJobLists = res.data.data.values;
                                            updatedJobLists = updatedJobLists.map(job => {
                                                if (job.job_id === jobId) {
                                                    job.is_applied = 1; 
                                                }
                                                return job;
                                            });
                                            setJobLists(updatedJobLists);
                                            setLoading(false);
                                        })
                                        .catch(err => {
                                            console.log(err);
                                            setLoading(false);
                                        });
                                    setApplyNowCont(appliedJobData?.is_applied == 0 ? "Job retrieved successfully" : "Job applied successfully");
                                    setTimeout(() => setApplyNowCont(''), 1000);
                                })
                                .catch(err => console.log(err));
                        })
                        .catch(err => console.log(err));

                
            }) 
            .catch(err => {
                setAllowUser(err.response?.status);
    
                if (err.response?.status === 401) {
                    const queryParams = new URLSearchParams(window.location.search);
                    const jobId = queryParams.get('job_id');
            
                    window.location.href = `https://accounts.dev.evzone.app/?redirecturl=${redirect_url}userapplication?job_id=${jobId}`;
                }
            });
    };

    const applyJobNav = useNavigate();

    const handleAllowUser = (id) => {
        applyJobNav(`?job_id=${id}`);    
        config.params = {
            job_id: id
        };    
        axios.get(jobGet(), config)
            .then(res => {
                setApplyJobGet(res.data.data);    
                axios.get(jobList(), config)
                    .then(res => setJobLists(res.data.data.values))
                    .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
    };
    

    const handleIconClick = (id) => {
        let data = {
            "job_id": id
        };

        axios.post(bookMark(), data, config)
            .then(res => {
                setBookMarkCont(res.data.message);
                setSelectedJobId(id); 

                setTimeout(() => {
                    setBookMarkCont('');
                    setSelectedJobId(null); 
                }, 1000);
            })
            .then(() => {
                axios.get(jobList(), config)
                    .then(res => setJobLists(res.data.data.values))
                    .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
    };

    const handleBookMarkIconClick=(id)=>{
        let data = {
            "job_id": id
        };

        axios.post(bookMark(), data, config)
            .then(() => {
                const queryParams = new URLSearchParams(location.search);
                const jobId = queryParams.get('job_id');
                    
                config.params = {
                                job_id: jobId
                            }
    
                axios.get(jobGet(), config)
                .then(res => {  
                    const applyJobData = res.data.data;
                    setApplyJobGet(applyJobData);                
                    const jobId = applyJobData.id; 
                    axios.get(jobList(), config)
                        .then(res => {
                            const jobListData = res.data.data.values;
                            const bookmarkedJob = jobListData.find(job => job.id === jobId);
                            if (bookmarkedJob) {
                                setJobLists(jobListData); 
                                setLoading(false);
                            } else {
                                setLoading(false);
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            setLoading(false);
                        }); 
                })
                .catch(err => {
                    console.log(err);
                });
            })
            .catch(err => console.log(err));                
    }
    

    return (
        <>
            <section className="page-title bg-white">
                <div className="auto-container">
                    <div className="title-outer">

                    </div>
                </div>
            </section>
            <section className='bg-fafbfc'>
                <div className="job-detail-outer">
                    <div className="auto-container">
                        <div className="row">
                            
                            <div className="content-column col-lg-8 col-md-12 col-sm-12">
                                <div className="job-block">
                                    <div className="inner-box p20">
                                        <div className="content pl-0 border-bottom-dashed">
                                            {/* <span className="company-logo"><img src="images/resource/company-logo/1-1.png" alt="" /></span> */}
                                            <h4><a href="#">{applyJobGet?.name}</a></h4>
                                            <h6 className='font12'> {applyJobGet?.company_detail?.name}
                                                <span className='float-right fz16'>
                                                    {applyJobGet?.salary_range}
                                                </span></h6>
                                            <ul className="job-info mt10">
                                                <p className='applyNowCont'>{applyNowCont}</p>
                                                <li className='pl-0 mr-0 w-100 mt20'><img className='mr10' src="/assets/images/icons/location.png" draggable='false' alt="" /> {applyJobGet?.company_detail?.location} <span className='float-right'>
                                                <a 
                                                    className={`theme-btn appy-btn ${applyJobGet?.is_applied === 1 ? 'disabled' : ''}`} 
                                                    aria-disabled={applyJobGet?.is_applied === 1} 
                                                    onClick={applyJobGet?.is_applied === 0 ? () => handleJobApply() : undefined}
                                                    >
                                                    {applyJobGet?.is_applied === 0 ? "Apply Now" : "Application Submitted"}
                                                </a>

                                                    {/* <a
                                                        onClick={value.is_applied !== 1 ? () => handleAllowUser(value.id) : null}
                                                        className={`theme-btn btn-style-two w-100 ${value.is_applied === 1 ? 'disabled' : ''}`}
                                                        style={{
                                                            pointerEvents: value.is_applied === 1 ? 'none' : 'auto',
                                                            opacity: value.is_applied === 1 ? 0.6 : 1,
                                                            backgroundColor: value.is_applied === 1 ? 'gray' : '',  // Set the background color when disabled
                                                        }}
                                                        >
                                                        {value.is_applied === 1 ? 'Application Submitted' : 'Apply Now'}
                                                    </a> */}
                                                </span></li>
                                            </ul>
                                        </div>
                                        <div className='mt20'><p className='filter-fresh'>3 Days ago <span className='color-blue'><i class="fa fa-circle color-black mr10 fz10" aria-hidden="true"></i>
                                            124 Applicant</span><span className='float-right'><button className="btn-share mr20"><i class="fa fa-share-alt" aria-hidden="true"></i>
                                            </button>

                                            <button
                                                className="btn-bookmark"
                                                onClick={()=> handleBookMarkIconClick(applyJobGet.id)}
                                                style={{ cursor: 'pointer' }}
                                                >
                                                {applyJobGet?.is_bookmark === 0 ? (
                                                    <BsBookmarkDash className="flaticon-bookmark" />
                                                ) : (
                                                    <BsBookmarkDashFill className="flaticon-bookmark" />
                                                )}
                                            </button>
                                            </span></p> </div>
                                    </div>
                                    <div className='inner-box mt20'>
                                        <ul className="display-flex justify-content-space-between">
                                            <li className="full-time"><img className='mr10 w-16' src="/assets/images/icons/dark-clock.svg" draggable='false' alt="" />Full Time</li>
                                            <li className="remote"><img className='mr10 w-16' src="/assets/images/icons/dark-briefcase.svg" draggable='false' alt="" />Remote</li>
                                            <li className="exp-year"><img className='mr10 w-16' src="/assets/images/icons/dark-activity-notification-square.svg" draggable='false' alt="" />{applyJobGet?.experience} Years</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="job-detail details-card job-details-height">
                                    <h4>Minimum Qualifications:</h4>
                                    <ul className="list-style-three">
                                        <li>10-15 years of experience</li>
                                        <li>PhD/ M.Tech/ M.E in Energy Engineering / Environmental Engineering / Building Science and technology / Construction </li>
                                        <li>Management with strong technical knowledge Demonstrated knowledge in Sustainable Design & Environmental Engineering concepts</li>
                                    </ul>
                                    <h4>Job Description</h4>
                                    <p>{applyJobGet?.description}</p>
                                    <h4>Responsibilities:</h4>
                                    <ul className='list-style-custom ml-30'>
                                        <li className='list-style-none'>1. Develop and implement a comprehensive exhibition program that highlights the history, significance, and cultural impact of automobiles.</li>
                                        <li className='list-style-none'>2. Conduct research on individual vehicles in the museum's collection, including their historical context, design, and technical specifications.</li>
                                        <li className='list-style-none'>3. Collaborate with the museum team to curate and install exhibits, ensuring accurate documentation, proper handling, and adherence to preservation standards..</li>
                                        <li className='list-style-none'>4. Identify potential acquisitions and make recommendations for expanding the museum's collection in alignment with its mission and vision.</li>
                                        <li className='list-style-none'>5. Plan and coordinate educational programs, workshops, and special events to engage visitors and enhance their understanding of automotive history</li>
                                    </ul>
                                    <h4>Application Requirements:</h4>
                                    <ul className="list-style-three mb-0">
                                        <li>Experience Certificate</li>
                                        <li>Education Certificate</li>
                                    </ul>
                                    <ul className='list-style-custom mt20 ml-0'>
                                        <li className='list-style-none pl0'><span><strong>Deadline&nbsp;:&nbsp;</strong></span>Application Last date <strong>{applyJobGet?.dead_line}</strong></li>
                                        <li className='list-style-none pl0'><span><strong>Interview Type&nbsp;:&nbsp;</strong></span>Walk In Interview</li>
                                        <li className='list-style-none pl0'><span><strong>Available Slots&nbsp;:&nbsp;</strong></span>{applyJobGet?.slots} Slot</li>
                                        <li className='list-style-none pl0'><span><strong>Period of Pay&nbsp;:&nbsp;</strong></span>Monthly</li>
                                        <li className='list-style-none pl0'><span><strong>Pay Currency&nbsp;:&nbsp;</strong></span>UGX, USD</li>
                                        <li className='list-style-none pl0'><span><strong>Allowances&nbsp;:&nbsp;</strong></span>Yes</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="sidebar-column col-lg-4 col-md-12 col-sm-12">
                                <aside className="sidebar">
                                    <div className="sidebar-widget">
                                        <h4 className="widget-title">Similer Jobs</h4>
                                        <div className="widget-content">

                                            <div className="job-block at-jlv17 at-home20 at-home18">
                                               { loading ? (
                                                Array.from({ length: 9 }).map((_, index) => (
                                                    <div key={index} className="news-block col-lg-12">
                                                        <div className="inner-box">
                                                            <div className="image-box">
                                                                <figure className="image loading-image"></figure>
                                                            </div>
                                                            <div className="lower-content space-between display-flex">
                                                                <div className="loading-text">
                                                                    <div className="animated-bg" style={{ height: '20px', width: '60%' }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                               ) :
                                                (jobLists && jobLists.map && jobLists.map((value)=>(
                                                    // <div key={i}>{value.name}</div>
                                                    <div key={value.id} className="col-xl-12 col-sm-6">
                                                        <div className="job-block at-jlv17 at-home20 at-home18">
                                                            <div className="inner-box">
                                                                <div className="tags">
                                                                <div className='bookMark' onClick={() => handleIconClick(value.id)} style={{ cursor: 'pointer' }}>
                                                                    {value.is_bookmark === 0 ? <BsBookmarkDash className="flaticon-bookmark" /> : <BsBookmarkDashFill className="flaticon-bookmark" />}
                                                                    {selectedJobId === value.id && <p className='bookMarkCont'>{bookMarkCont}</p>}
                                                                </div>
                                                                </div>
                                                                <div className="content ps-0">
                                                                    <div className="d-flex mb-3 mb-md-0 align-top">
                                                                        <span className="position-relative mr15"><img className="wa w60" src="/assets/images/icons/ev-logo-icon.png" draggable='false' alt="" /></span>
                                                                        <div className="text-start">
                                                                            <h4 className="fz22 mb-2"><a href="#">{value.name}</a></h4>
                                                                            <h6 className='font12'>{value.company_detail.name}</h6>
                                                                            <ul className="job-other-info at-jsv6 at-jsv17 ms-0">
                                                                                <li className="time2 fz12 border-0 ps-0 me-0 mb-0 bg-none"><img className='mr10' src="/assets/images/icons/location.png" draggable='false' alt="" />{value.company_detail.location}</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-lg-12'>
                                                                            <p className='text'>{value.description}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className='col-lg-12'>
                                                                            <ul className="job-other-info at-jsv6 at-jsv17 mt15 ms-0 justify-content-md-between">
                                                                                <li className="time2 border-0 me-0 light-y-bg fz12"><img className='mr10 w-16' src="/assets/images/icons/dark-clock.svg" draggable='false' alt="" />Full Time</li>
                                                                                <li className="time2 border-0 light-blue-bg fz12"><img className='mr10 w-16' src="/assets/images/icons/dark-briefcase.svg" draggable='false' alt="" />Remote</li>
                                                                                <li className="time2 border-0 bg-D3EFD8 fz12"><img className='mr10 w-16' src="/assets/images/icons/dark-activity-notification-square.svg" draggable='false' alt="" />{value.experience} Year</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row mt10'>
                                                                        <div className='col-lg-12'><span className='mr15 fz14 fw-600'>3 Days ago</span><span><i class="fa fa-circle fz10 mr10" aria-hidden="true"></i></span>
                                                                            <span className='text-blue fz14 fw-600'>124 Applicant</span></div>
                                                                    </div>
                                                                    <div className="align-items-center justify-content-md-between mt10">
                                                                        <div className="align-items-center mb-3 mb-md-0">
                                                                        {/* <a onClick={()=> handleAllowUser(value.id)} class="theme-btn btn-style-two w-100">
                                                                            { value?.is_applied === 1 ? 'Application Submitted' : 'Apply Now'}
                                                                        </a> */}
                                                                        <a
                                                                            onClick={value?.is_applied !== 1 ? () => handleAllowUser(value?.id) : null}
                                                                            className={`theme-btn btn-style-two w-100 ${value?.is_applied === 1 ? 'disabled' : ''}`}
                                                                            style={{
                                                                                pointerEvents: value?.is_applied === 1 ? 'none' : 'auto',
                                                                                opacity: value?.is_applied === 1 ? 0.6 : 1,
                                                                                backgroundColor: value?.is_applied === 1 ? 'gray' : '',  // Set the background color when disabled
                                                                            }}
                                                                            >
                                                                            {value?.is_applied === 1 ? 'Application Submitted' : 'Apply Now'}
                                                                        </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )))}
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default JobDetails