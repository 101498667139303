import React, { useEffect, useRef, useState } from 'react'
import PersonDetails from './PersonDetails';
import axios from 'axios';
import { Configuration, jobList } from '../Api/Url';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { IoSearch } from "react-icons/io5";
import { PiPaperclipThin } from "react-icons/pi";
import { MdCall } from "react-icons/md";
import { IoMdVideocam } from "react-icons/io";
import { FaCircleInfo } from "react-icons/fa6";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BsPaperclip } from "react-icons/bs";
import { MdOutlineEmojiEmotions } from "react-icons/md";
import { FaDownload } from 'react-icons/fa';
import { IoMdImage } from "react-icons/io";
import { IoDocumentText } from "react-icons/io5";
import { FiMonitor } from "react-icons/fi";
import { CiChat1 } from "react-icons/ci";
import { GoArrowRight } from "react-icons/go";
import { FaBookmark } from "react-icons/fa6";
import { MdUpload } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";

const UserHome = ({loginId}) => {   
  
  const [isApply, setIsApply] = useState([]);
  const [jobApplyCount, setJobApplyCount] = useState([]);
  const [applied, setApplied] = useState(false);
  const [message, setMessage] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const alreadyLogged = useRef(false);
  let config = Configuration();
  const nav = useNavigate();
  const location = useLocation();
  const pathLocation = (location.pathname.split('/'));
  const locationPath = pathLocation[pathLocation.length - 1];

  const [global, setGlobal] = useState();

  useEffect(() => {
    axios
      .get(jobList(), config)
      .then((res) => {
        const values = res.data.data.values; 
        setIsApply(values);
      })
      .catch((err) => console.log('Error:', err));
  }, []);

  useEffect(() => {
    if (Array.isArray(isApply) && isApply.length > 0 && !alreadyLogged.current) {
      if (isApply.some((obj) => obj.is_applied === 1)) {
        setApplied(true);
        alreadyLogged.current = true;
      } else {
        setApplied(false);
      }

        setJobApplyCount(isApply.filter(item => item.is_applied === 1).length)
  
    }
  }, [isApply]); 

  useEffect(() => {
    const globalValue = window.globalValue;
    setGlobal(globalValue);
  }, [window.globalValue]);

  const handleShowJobs=()=>{
    nav(`/u/${loginId}/jobs`)
  }

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendClick = () => {
      setMessage(""); 
  };

  const burgerBtnClick =()=>{
      const pdfDetail = document.querySelector('.pdfDetail');
      if (pdfDetail) {
          pdfDetail.style.display = pdfDetail.style.display === 'none' ? 'block' : 'none';
      }
      setIsExpanded(!isExpanded);
  }

  const handleVdoIcon=()=>{
    nav(`/u/${global}/vdo`)
  }

  const handleAdoIcon=()=>{
    nav(`/u/${global}/ado`)
  }

  const handleJobDetail = (id)=>{
    nav(`/u/${global}/jobdetails?job_id=${id}`);
  }

  return (
    <div className='row userHomeBg auto-container'>
      <div className='col-12 col-sm-12 col-md-12 col-lg-3 colSpace'>
        <PersonDetails jobIsApply={applied} jobApplyCount={jobApplyCount}/>
      </div>

      { locationPath === 'message' ? (
        <div className="col-6 col-sm-9 col-md-9 col-lg-9 messageCont auto-container">
          <div className="col-6 col-sm-3 col-md-3 col-lg-3 messageCol">
              <div className='messageColSearchBar'>
                  <div className="search-box">
                      <IoSearch className="search-icon"/>
                      <input type="text" placeholder="Search Messages" />
                  </div>
              </div>
              <div>
                  <div className='msgUserDetails'>
                      <div className='msgUserDetail msgUserDetailColor'>
                          <div className='msgUser'>
                              <img src="/assets/images/msg1.png" alt="userImg"/>
                              <PiPaperclipThin />
                          </div>
                          <div className='msgUserDetailCont'>
                              <div className='msgUserName'>
                                  <div className='msgUserNameDetail'>
                                      <h6>Annecte Black</h6>
                                      <h5>UI/UX Designer</h5>
                                  </div>
                                  <div>
                                      <p>10.54 PM</p>
                                  </div>
                              </div>
                              <div className='msgUserCont'>
                                  <p>This section of the letter is where you can make a pitch for your candidacy.</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <hr />
                  <div className='msgUserDetails'>
                      <div className='msgUserDetail'>
                          <div className='msgUser'>
                              <img src="/assets/images/msg2.png" alt="userImg"/>
                          </div>
                          <div className='msgUserDetailCont'>
                              <div className='msgUserName'>
                                  <div className='msgUserNameDetail'>
                                      <h6>Damell Steward</h6>
                                      <h5>Sernior UI Designer</h5>
                                  </div>
                                  <div>
                                      <p>10.54 PM</p>
                                  </div>
                              </div>
                              <div className='msgUserCont'>
                                  <p>This section of the letter is where you can make a pitch for your candidacy.</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <hr />
                  <div className='msgUserDetails'>
                      <div className='msgUserDetail'>
                          <div className='msgUser'>
                              <img src="/assets/images/msg3.png" alt="userImg"/>
                              <PiPaperclipThin />
                          </div>
                          <div className='msgUserDetailCont'>
                              <div className='msgUserName'>
                                  <div className='msgUserNameDetail'>
                                      <h6>Rober Fox</h6>
                                      <h5>Product Designer</h5>
                                  </div>
                                  <div>
                                      <p>10.54 PM</p>
                                  </div>
                              </div>
                              <div className='msgUserCont'>
                                  <p>This section of the letter is where you can make a pitch for your candidacy.</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <hr />
                  <div className='msgUserDetails'>
                      <div className='msgUserDetail'>
                          <div className='msgUser'>
                              <img src="/assets/images/msg4.png" alt="userImg"/>
                          </div>
                          <div className='msgUserDetailCont'>
                              <div className='msgUserName'>
                                  <div className='msgUserNameDetail'>
                                      <h6>Jane Cooper</h6>
                                      <h5>Web Designer</h5>
                                  </div>
                                  <div>
                                      <p>10.54 PM</p>
                                  </div>
                              </div>
                              <div className='msgUserCont'>
                                  <p>This section of the letter is where you can make a pitch for your candidacy.</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <hr />
                  <div className='msgUserDetails'>
                      <div className='msgUserDetail'>
                          <div className='msgUser'>
                              <img src="/assets/images/msg5.png" alt="userImg"/>
                          </div>
                          <div className='msgUserDetailCont'>
                              <div className='msgUserName'>
                                  <div className='msgUserNameDetail'>
                                      <h6>Esther Howard</h6>
                                      <h5>UX Designer</h5>
                                  </div>
                                  <div>
                                      <p>10.54 PM</p>
                                  </div>
                              </div>
                              <div className='msgUserCont'>
                                  <p>This section of the letter is where you can make a pitch for your candidacy.</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <hr />
                  <div className='msgUserDetails'>
                      <div className='msgUserDetail'>
                          <div className='msgUser'>
                              <img src="/assets/images/msg6.png" alt="userImg"/>
                          </div>
                          <div className='msgUserDetailCont'>
                              <div className='msgUserName'>
                                  <div className='msgUserNameDetail'>
                                      <h6>Wade Warren</h6>
                                      <h5>Product Manager</h5>
                                  </div>
                                  <div>
                                      <p>10.54 PM</p>
                                  </div>
                              </div>
                              <div className='msgUserCont'>
                                  <p>This section of the letter is where you can make a pitch for your candidacy.</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <hr />
                  <div className='msgUserDetails'>
                      <div className='msgUserDetail'>
                          <div className='msgUser'>
                              <img src="/assets/images/msg2.png" alt="userImg"/>
                          </div>
                          <div className='msgUserDetailCont'>
                              <div className='msgUserName'>
                                  <div className='msgUserNameDetail'>
                                      <h6>Damell Steward</h6>
                                      <h5>Sernior UI Designer</h5>
                                  </div>
                                  <div>
                                      <p>10.54 PM</p>
                                  </div>
                              </div>
                              <div className='msgUserCont'>
                                  <p>This section of the letter is where you can make a pitch for your candidacy.</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="col-6 col-sm-9 col-md-9 col-lg-9">
              <div className='userMsgNav'>
                  <div className='userIcon'>
                      <div className='userMsg'>
                          <img src="/assets/images/msg1.png" alt="userImg"/>
                      </div>
                      <div>
                          <h5>Annette Black</h5>
                          <p>Typing...</p>
                      </div>
                  </div>
                  <div className='userMsgIcons'>
                      <div className='userMsgClIcons'>
                          <MdCall className='clIcon' onClick={handleAdoIcon}/>
                          <IoMdVideocam className='clIcon' onClick={handleVdoIcon}/>
                          <FaCircleInfo className='clIcon'/>
                      </div>
                      <div>
                          <BsThreeDotsVertical style={{color:'blue'}}/>
                      </div>
                  </div>
              </div>
              <div className='row'>
                  <div className={isExpanded ? "col-6 col-sm-12 col-md-12 col-lg-12 userChatContWhole" 
                      : "col-6 col-sm-8 col-md-8 col-lg-8 userChatContWhole"}>
                      <div className='burgerBtnDiv'>
                          <RxHamburgerMenu className='burgerBtn' onClick={burgerBtnClick}/>
                      </div>
                      <div className='userChatCont'>
                          <div>
                              <div className='userChatContName chatUser'>
                                  <h5>Annette Black</h5>
                                  <p>12:33</p>
                              </div>
                              <div className='userChatContPara chatUserPara'>
                                  <p>In this article, I’d like to reacquaint you with the humble workhorse of communication that is the paragraph. Paragraphs are everywhere. In fact, at the high risk of stating the obvious, you are reading one now. Despite their ubiquity, we frequently neglect their presentation. This is a mistake.</p>
                              </div>
                          </div>
                          <img src="/assets/images/msg1.png" alt="userImg"/>
                      </div>
                      <div className='userChatCont userChatContSolo'>
                          <img src="/assets/images/userProfile.png" alt="userImg"/>
                          <div className='userChatContIcons'>
                              <div>
                                  <div className='userChatContName'>
                                      <h5>Nasim</h5>
                                      <p>12:34</p>
                                  </div>
                                  <div className='userChatContPara'>
                                      <p>What is the best way to use email to apply for jobs?</p>
                                  </div>
                              </div>
                              <div className={isExpanded ? 'userChatIconsMargin' : 'userChatIcons' } >
                                  <MdOutlineEmojiEmotions />    
                                  <CiChat1 />
                                  <GoArrowRight />
                                  <FaBookmark className='bookmarkIcon'/>
                                  <BsThreeDotsVertical />
                              </div>
                          </div>
                      </div>
                      <div className='userChatCont'>
                          <div>
                              <div className='userChatContName chatUser'>
                                  <h5>Annette Black</h5>
                                  <p>12:34</p>
                              </div>
                              <div className='userChatContPara chatUserPara'>
                                  <p>When you send your resume , it's important to include a concise cover letter.</p>
                                  <img src="/assets/images/screenshot.JPG" alt="" />
                              </div>
                          </div>
                          <img src="/assets/images/msg1.png" alt="userImg"/>
                      </div>
                      <div className='userChatCont'>
                          <img src="/assets/images/userProfile.png" alt="userImg"/>
                          <div>
                              <div className='userChatContName'>
                                  <h5>Nasim</h5>
                                  <p>12:33</p>
                              </div>
                              <div className='userChatContPara'>
                                  <p>When you send your resume , it's important to include a concise cover letter.</p>
                              </div>
                          </div>
                      </div>
                      <div className='userChatCont'>
                          <div>
                              <div className='userChatContName chatUser'>
                                  <h5>Annette Black</h5>
                                  <p>12:33</p>
                              </div>
                              <div className='userChatContPara chatUserPara'>
                                  <p>In this article, I’d like to reacquaint you with the humble workhorse of communication that is the paragraph. Paragraphs are everywhere. In fact, at the high risk of stating the obvious, you are reading one now. Despite their ubiquity, we frequently neglect their presentation. This is a mistake.</p>
                              </div>
                          </div>
                          <img src="/assets/images/msg1.png" alt="userImg"/>
                      </div>  
                      <div className='userChatCont'>
                          <img src="/assets/images/userProfile.png" alt="userImg"/>
                          <div>
                              <div className='userChatContName'>
                                  <h5>Nasim</h5>
                                  <p>12:33</p>
                              </div>
                              <div className='userChatContPara'>
                                  <p>In this article, I’d like to reacquaint you with the humble workhorse of communication that is the paragraph. Paragraphs are everywhere. In fact, at the high risk of stating the obvious, you are reading one now. Despite their ubiquity, we frequently neglect their presentation. This is a mistake.</p>
                              </div>
                          </div>
                      </div>
                      <div className='userMsgBtn'>
                          <div className='userChatContTyping'>
                              <img src="/assets/images/msg1.png" alt="userImg"/>
                              <h5>Annette is Typing...</h5>
                          </div>
                          <div className="userMsgInputBox">
                              <textarea
                                  value={message}
                                  onChange={handleInputChange}
                                  placeholder="Message here..."
                                  className="autoResizeTextarea"
                                  rows="2"
                                  cols="200"
                              />
                              <div className="iconContainer">
                                  <BsPaperclip className='userMsgIcon'/>
                                  <MdOutlineEmojiEmotions className='userMsgIcon'/>
                              </div>
                              <button onClick={handleSendClick} className="sendButton">
                                  Send
                              </button>
                          </div>
                      </div>
                  </div>
                  <div className="col-6 col-sm-4 col-md-4 col-lg-4 pdfDetail">
                      <div>
                          <div className="userChatFileContainer">
                              <div className="userChatFileDetails">
                                  <div className="userChatIconContainer">
                                      <IoMdImage className="userChatFileIcon"/>
                                      <p className="file-name">Project Management App</p>
                                  </div>
                                  <p className="file-info">JPG • 1.2 MB</p>
                              </div>
                              <div className="userChatDownloadIcon">
                                  <FaDownload />
                              </div>
                          </div>
                          <div className="userChatFileContainer">
                              <div className="userChatFileDetails">
                                  <div className="userChatIconContainer">
                                      <IoDocumentText  className="userChatFileIcon"/>
                                      <p className="file-name">Project Management App</p>
                                  </div>
                                  <p className="file-info">JPG • 1.2 MB</p>
                              </div>
                              <div className="userChatDownloadIcon">
                                  <FaDownload />
                              </div>
                          </div>
                          <div className="userChatFileContainer">
                              <div className="userChatFileDetails">
                                  <div className="userChatIconContainer">
                                      <FiMonitor className="userChatFileIcon"/>
                                      <p className="file-name">Project Management App</p>
                                  </div>
                                  <p className="file-info">JPG • 1.2 MB</p>
                              </div>
                              <div className="userChatDownloadIcon">
                                  <FaDownload />
                              </div>
                          </div>
                          <div className="userChatFileContainer">
                              <div className="userChatFileDetails">
                                  <div className="userChatIconContainer">
                                      <IoMdImage className="userChatFileIcon"/>
                                      <p className="file-name">Project Management App</p>
                                  </div>
                                  <p className="file-info">JPG • 1.2 MB</p>
                              </div>
                              <div className="userChatDownloadIcon">
                                  <FaDownload />
                              </div>
                          </div>
                          
                      </div>
                      <div class="userChatPdfBtn">
                          <button className="userChatUploadBtn">Filter</button>
                          <button className="userChatUploadBtn">
                              <MdUpload className="userChatUploadIcon" />
                              <span>Upload File</span>
                          </button>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      ) : (
        applied === true ? (
          <div className='col-12 col-sm-12 col-md-12 col-lg-9 colSpaceRight'>
  
          <div className="content">
            <div className='jobCont'>

            <NavLink to={`/u/${global}/`} end className={({ isActive }) => (isActive ? 'navJobActive' : 'JobActive')}>Jobs Applied</NavLink>
            <NavLink to={`/u/${global}/savedJob`} className={({ isActive }) => (isActive ? 'navJobActive' : 'JobActive')}>Saved Jobs</NavLink>

            </div>

            { locationPath === 'savedJob' ? (
                isApply.filter(item => item.is_bookmark === 1).length > 0 && (
                    isApply
                        .filter(item => item.is_bookmark === 1)
                        .map((val, i) => (
                        <div key={i} className="jobDetailsCont">
                            <div className="jobName">
                            <h3>{val.name}</h3>
                            <p>Applied: 12 day(s) ago</p>
                            </div>
                            <div className="jobPlace">
                            <p>{val?.company_detail?.name}</p>
                            <span>{val?.company_detail?.location}, CA</span>
                            </div>
                            <div className="jobDetail">
                            <p>{val.description}</p>
                            <button onClick={()=>handleJobDetail(val.id)}>Read More</button>
                            </div>
                        </div>
                    )))
                ) 
                : (
                       isApply.filter(item => item.is_applied === 1).length > 0 && (
                        isApply
                            .filter(item => item.is_applied === 1)
                            .map((val, i) => (
                            <div key={i} className="jobDetailsCont">
                                <div className="jobName">
                                <h3>{val.name}</h3>
                                <p>Applied: 12 day(s) ago</p>
                                </div>
                                <div className="jobPlace">
                                <p>{val?.company_detail?.name}</p>
                                <span>{val?.company_detail?.location}, CA</span>
                                </div>
                                <div className="jobDetail">
                                <p>{val.description}</p>
                                <button onClick={()=>handleJobDetail(val.id)}>Read More</button>
                                </div>
                            </div>
                            )))
                  )
            }
          </div>
          
        </div>
        ) : (
          <div className='col-12 col-sm-12 col-md-12 col-lg-9 colSpaceRight'>
            <div className="content job">
              <div className='imgCont'>
                <img src="/assets/images/office.png"/>
              </div>
              <h2>Ahh...We're sorry.</h2>
              <h2>You have not applied any job yet.</h2>
              <p>Don't worry. We are here to help you, you can start searching your dream jobs.</p>
              <div className='imgBtn'>
                <button onClick={handleShowJobs}>Start looking for Jobs</button>
              </div>
            </div>
          </div>
        )
      )}

    </div>    
  )
}

export default UserHome
