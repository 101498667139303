import React from 'react'
import UserAppTab from '../components/userapptab';
const UserApplication = () => {
    return (
        <>
            <section className='userapp-section bg-fafbfc'>
                <div className="auto-container">
                    <div className='inner-box'>
                        <div className="sec-title">
                            <h3>User Application</h3>
                        </div>
                            <UserAppTab />
                    </div>
                </div>
            </section>
        </>
    );
}
export default UserApplication