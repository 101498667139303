import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { redirect_url } from '../Api/Url';

const Header = ({loginId, setLoginId}) => {

    const [authUser, setAuthUser] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const xAuthUserParam = queryParams.get('x_authuser');
        const jobIdParam = queryParams.get('job_id');
    
        if (xAuthUserParam) {
            let newPath;
            if (location.pathname === '/') {
                newPath = `/u/${xAuthUserParam}/`;
            } else if(jobIdParam) {
                newPath = `/u/${xAuthUserParam}${location.pathname}?job_id=${jobIdParam}`;
            }
            navigate(newPath);
        }
  }, [navigate, location]);

    // useEffect(() => {
    //     const queryParams = new URLSearchParams(location.search);
    //     const xAuthUserParam = queryParams.get('x_authuser');

    //     if (xAuthUserParam) {
    //         let newPath;

    //         if (location.pathname === '/') {
    //             newPath = `/u/${xAuthUserParam}/`;
    //         } else {
    //             newPath = `/u/${xAuthUserParam}${location.pathname}`;
    //         }
    //         navigate(newPath);
    //     }
    // }, [navigate, location]);


    const handleLogin = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const query = queryParams.get('job_id');
        let pathname;
        let url;
    
        if (query) {
            pathname = 'userapplication';
        }
         else {
            url = window.location.pathname;
            const segments = url.split('/');
            pathname = segments[1]
        }
    
        window.location.href = `https://accounts.dev.evzone.app/?redirecturl=${redirect_url}${pathname}`;
    };

    useEffect(() => {
        const path = window.location.pathname;
        const match = path.match(/\/u\/(\d+)/);
        const authUserId = match ? match[1] : null;
        setAuthUser(authUserId);
    }, []);

    const [isLoading, setIsLoading] = useState(true);
    const redirect = useNavigate();

    useEffect(() => {
        const fetchGlobalValue = () => {
            return new Promise((resolve) => {
                setTimeout(() => {
                resolve(window.globalValue);
                }, 1000); 
            });
        };

        fetchGlobalValue().then((globalValue) => {
        const pathSegments = location.pathname.split('/');
        const currectPath = pathSegments[pathSegments.length - 1];

        const queryParams = new URLSearchParams(location.search);
        const teamIdParam = queryParams.get('team_id');
        const jobIdParam = queryParams.get('job_id');
        setLoginId(globalValue);
        
        if (globalValue == null) {
            const hasReloaded = sessionStorage.getItem('hasReloaded');
            if (!hasReloaded) {
                sessionStorage.setItem('hasReloaded', 'true');
                window.location.reload();
            } 
            else if(teamIdParam){
                redirect(`/${currectPath}?team_id=${teamIdParam}`);
            }
            else if(jobIdParam){
                redirect(`/${currectPath}?job_id=${jobIdParam}`);
            }
            else{
                redirect(`/${currectPath}`);
            }

            // else if(teamIdParam) {
            //     if (teamIdParam) {
            //         redirect(`/${currectPath}?team_id=${teamIdParam}`);
            //     } else {
            //         redirect(`/${currectPath}`);
            //     }
            // }
            //  else if(jobIdParam) {
            //     if (jobIdParam) {
            //         redirect(`/${currectPath}?job_id=${jobIdParam}`);
            //     } else {
            //         redirect(`/${currectPath}`);
            //     }
            // }
        }
        else if(globalValue){
            if(teamIdParam && globalValue){
                redirect(`/u/${globalValue}/${currectPath}?team_id=${teamIdParam}`)
            }
            else if(jobIdParam && globalValue){
                redirect(`/u/${globalValue}/${currectPath}?job_id=${jobIdParam}`)
            }
            else{
                redirect(`/u/${globalValue}/${currectPath}`)
            }
        }


        // else if(teamIdParam && globalValue){
        //     if(teamIdParam && globalValue){
        //         redirect(`/u/${globalValue}/${currectPath}?team_id=${teamIdParam}`)
        //     }
        //     else{
        //         redirect(`/u/${globalValue}/${currectPath}`)
        //     }
        // }
        // else if(jobIdParam && globalValue){
        //     console.log('globalValue', globalValue)
        //     if(jobIdParam && globalValue){
        //         redirect(`/u/${globalValue}/${currectPath}?job_id=${jobIdParam}`)
        //     }
        //     else{
        //         redirect(`/u/${globalValue}/${currectPath}`)
        //     }
        // }

        
        setIsLoading(false);
        });
    }, [loginId,setLoginId,location.pathname,redirect]);

    useEffect(() => {
        if (loginId === undefined) {
        setIsLoading(true);
        } else {
        setIsLoading(false);
        }
    }, [loginId]);

    return (
        <> 
        {isLoading ? (
            <header className="main-header">
                <div className="auto-container">
                    <div className="main-box">
                        <div className="nav-outer">
                            <div className="logo-box">
                                <div className="logo">
                                <NavLink to={loginId ? `/u/${loginId}/` : '/'}>
                                    <img
                                        className="logo-width"
                                        src="/assets/images/logo/ev-zone-logo.svg"
                                        draggable="false"
                                        alt="EV Zone Logo"
                                        title="EV Zone"
                                    />
                                </NavLink>

                                </div>
                            </div>
                            <nav className="nav main-menu">
                                <ul className="navigation" id="navbar">
                                    <li>
                                    <NavLink
                                        to={loginId ? `/u/${loginId}/teams` : '/teams'}
                                        // to='/teams'
                                        className={({ isActive }) => (isActive ? 'navActive' : '')}
                                    >
                                            Teams
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={loginId ? `/u/${loginId}/jobs` : '/jobs'}
                                            // to='/jobs'
                                            className={({ isActive }) => (isActive ? 'navActive' : '')}
                                        >
                                            Jobs
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink 
                                            to={loginId ? `/u/${loginId}/locations` : '/locations'}
                                            // to='/locations'
                                            className={({ isActive }) => (isActive ? 'navActive' : '')}
                                        >
                                            Locations
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink 
                                            to={loginId ? `/u/${loginId}/PerksBenefits` : '/PerksBenefits'}
                                            // to='/PerksBenefits'
                                            className={({ isActive }) => (isActive ? 'navActive' : '')}
                                        >
                                            Our Perks
                                        </NavLink>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="outer-box">
                            <a href="#" className="head-icon">
                                <i className="fa-regular fa-bell"></i>
                            </a>
                            {/* <a href="#" className="head-icon">
                                <i className="fa-solid fa-grid"></i>
                            </a> */}
                            <div className="btn-box">
                                {
                                    !loginId && (
                                        <button onClick={handleLogin} className="theme-btn btn-style-one">
                                            Sign In
                                        </button>
                                    ) 
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mobile-header">
                        <div className="logo">
                            <NavLink
                                to={loginId !== null ? `/u/${loginId}/home` : '/home'}
                            //  to="/home"
                            >
                                <img
                                    src="/assets/images/logo/ev-zone-logo.svg"
                                    draggable="false"
                                    alt="EV Zone Logo"
                                    title="EV Zone"
                                />
                            </NavLink>
                        </div>
                        <div className="nav-outer clearfix">
                            <div className="outer-box">
                                <div className="login-box">
                                    <a href="#" className="call-modal">
                                        <span className="icon-user"></span>
                                    </a>
                                </div>
                                <a href="#nav-mobile" className="mobile-nav-toggler navbar-trigger">
                                    <span className="flaticon-menu-1"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div id="nav-mobile"></div>
                </div>
            </header>
            ) : (
            <header className="main-header">
                <div className="auto-container">
                    <div className="main-box">
                        <div className="nav-outer">
                            <div className="logo-box">
                                <div className="logo">
                                {/* authUser && authUser !== undefined && authUser !== null */}
                                <NavLink to={loginId ? `/u/${loginId}/` : '/'}>
                                    <img
                                        className="logo-width"
                                        src="/assets/images/logo/ev-zone-logo.svg"
                                        draggable="false"
                                        alt="EV Zone Logo"
                                        title="EV Zone"
                                    />
                                </NavLink>

                                </div>
                            </div>
                            <nav className="nav main-menu">
                                <ul className="navigation" id="navbar">
                                    <li>
                                    <NavLink
                                        to={loginId ? `/u/${loginId}/teams` : '/teams'}
                                        // to='/teams'
                                        className={({ isActive }) => (isActive ? 'navActive' : '')}
                                    >
                                            Teams
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to={loginId ? `/u/${loginId}/jobs` : '/jobs'}
                                            // to='/jobs'
                                            className={({ isActive }) => (isActive ? 'navActive' : '')}
                                        >
                                            Jobs
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink 
                                            to={loginId ? `/u/${loginId}/locations` : '/locations'}
                                            // to='/locations'
                                            className={({ isActive }) => (isActive ? 'navActive' : '')}
                                        >
                                            Locations
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink 
                                            to={loginId ? `/u/${loginId}/PerksBenefits` : '/PerksBenefits'}
                                            // to='/PerksBenefits'
                                            className={({ isActive }) => (isActive ? 'navActive' : '')}
                                        >
                                            Our Perks
                                        </NavLink>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="outer-box">
                            <a href="#" className="head-icon">
                                <i className="fa-regular fa-bell"></i>
                            </a>
                            {/* <a href="#" className="head-icon">
                                <i className="fa-solid fa-grid"></i>
                            </a> */}
                            <div className="btn-box">
                                {
                                    !loginId && (
                                        <button onClick={handleLogin} className="theme-btn btn-style-one">
                                            Sign In
                                        </button>  
                                    ) 
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mobile-header">
                        <div className="logo">
                            <NavLink
                                to={loginId !== null ? `/u/${loginId}` : '/'}
                            //  to="/home"
                             >
                                <img
                                    src="/assets/images/logo/ev-zone-logo.svg"
                                    draggable="false"
                                    alt="EV Zone Logo"
                                    title="EV Zone"
                                />
                            </NavLink>
                        </div>
                        <div className="nav-outer clearfix">
                            <div className="outer-box">
                                <div className="login-box">
                                    <a href="#" className="call-modal">
                                        <span className="icon-user"></span>
                                    </a>
                                </div>
                                <a href="#nav-mobile" className="mobile-nav-toggler navbar-trigger">
                                    <span className="flaticon-menu-1"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div id="nav-mobile"></div>
                </div>
            </header>
        )}
        </>
    );
};

export default Header;
