import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './header';
import Footer from './footer';
import Homepage from '../pages/Homepage';
import Jobs from '../pages/jobs';
import Teams from '../pages/teams';
import UserHome from '../pages/userHome';
import PerksBenefits from '../pages/perksbenefits';
import JobsFilter from '../pages/jobsfilter';
import JobDetails from '../pages/jobdetails';
import Register from '../pages/register';
import UserApplication from '../pages/userapplication';
import Locations from '../pages/locations';
import { Provider } from 'react-redux';
import { Store } from '../Store/Store';
import Resume from '../pages/resume';
import Vdo from '../pages/vdo';
import Ado from '../pages/Ado';

const BaseLayouts = () => {

    const [authUser, setAuthUser] = useState(null);
    const [loginId, setLoginId] = useState(null);


useEffect(() => {
    const path = window.location.pathname;
    const match = path.match(/\/u\/(\d+)/);
    const authUserId = match ? match[1] : null;
    setAuthUser(authUserId);
}, []);

useEffect(() => {
  const globalValue = window.globalValue;
  setLoginId(globalValue);
}, [window.globalValue]);

    return (
        <>
        <Provider store={Store}>
            <React.Fragment>
                <BrowserRouter>
                    <Header loginId={loginId} setLoginId={setLoginId}/>
                    
                    {loginId && loginId !== undefined && loginId !== null ? (
                        <Routes>
                            {/* <Route path={`/u/${loginId}/userHomeJob`} element={<UserHomeJob />} /> */}
                            {/* <Route path={`/u/${loginId}/home`} element={<UserHome/>}/> */}
                            <Route path={`/u/${loginId}/`} element={<UserHome />} />
                            <Route path={`/u/${loginId}/message`} element={<UserHome />} />
                            <Route path={`/u/${loginId}/resume`} element={<Resume/>} />
                            <Route path={`/u/${loginId}/vdo`} element={<Vdo/>} />
                            <Route path={`/u/${loginId}/ado`} element={<Ado/>} />

                            <Route path={`/u/${loginId}/jobs`} element={<Jobs />} />
                            <Route path={`/u/${loginId}/teams`} element={<Teams />} />
                            <Route path={`/u/${loginId}/PerksBenefits`} element={<PerksBenefits />} />
                            <Route path={`/u/${loginId}/jobsfilter`} element={<JobsFilter />} />
                            <Route path={`/u/${loginId}/jobdetails`} element={<JobDetails />} />
                            <Route path={`/u/${loginId}/register`} element={<Register />} />
                            <Route path={`/u/${loginId}/userapplication`} element={<UserApplication />} />
                            <Route path={`/u/${loginId}/locations`} element={<Locations />} />

                            {/* <Route path={`/u/${loginId}/jobApplied`} element={<UserHome />} /> */}
                            <Route path={`/u/${loginId}/savedJob`} element={<UserHome />} />

                            <Route path="*" element={<UserHome />} />
                        </Routes>
                    ) : (
                        <Routes>
                            <Route path="/" element={<Homepage loginId={loginId} setLoginId={setLoginId}/>} />
                            <Route path="/home" element={<Homepage loginId={loginId} setLoginId={setLoginId}/>} />
                            <Route path="/jobs" element={<Jobs />} />
                            <Route path="/teams" element={<Teams />} />
                            <Route path="/PerksBenefits" element={<PerksBenefits />} />
                            <Route path="/jobsfilter" element={<JobsFilter />} />
                            <Route path="/jobdetails" element={<JobDetails />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/userapplication" element={<UserApplication />} />
                            <Route path="/locations" element={<Locations />} />

                            <Route path="*" element={<Homepage />} />
                        </Routes>
                    )}

                    <Footer />
                </BrowserRouter>
            </React.Fragment>
            </Provider>
        </>
    );
};

export default BaseLayouts;
