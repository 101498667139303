import React from 'react'
import { HiOutlinePencil } from "react-icons/hi2";
import { TbWorld } from "react-icons/tb";
import { TbClockHour3Filled } from "react-icons/tb";

const userHomeJob = () => {
  console.log('userHome')
  return (
    <div className='row userHomeBg auto-container'>
      <div className='col-6 col-sm-3 col-md-3 col-lg-3 colSpace'>
        <div className="content">
          <div className='profileEdit'>
            <img src="/assets/images/userProfile.png"/>
            <HiOutlinePencil className='profileEditIcon'/>
          </div>
          <div className='userName'>
            <h4>Satvik Brown</h4>
            <p>Los Angeies, CA 90036</p>
          </div>
          <div>
            <div className='userDetails'>
                <p>Most Recent Job</p>
                <h5>Oracle</h5>
            </div>
            <div className='userDetails'>
                <p>Last Qualification</p>
                <h5>B.Tech</h5>
            </div>
            <div className='userDetails'>
                <p>Work Experience</p>
                <h5>5.5 Years</h5>
            </div>
            <div className='usermail'>
                <TbWorld className='UserHomeIcon'/>
                <p>setvik.kk@gmail.com</p>
            </div>
            <div className='usermail'>
                <TbClockHour3Filled className='UserHomeIcon'/>
                <p>0123456789</p>
                <button>Pending</button>
            </div>
            <div className='userResume'>
                <button>Upload your latest resume</button>
            </div>
          </div>
        </div>
        <div className="content" style={{backgroundColor:'#2d2c4d', color:'white'}}>
            Jobs Applied(4)
        </div>
        <div className="content">
            Saved Jobs(11)
        </div>
        <div className="content">
            Message
        </div>
      </div>

      <div className='col-6 col-sm-9 col-md-9 col-lg-9 colSpaceRight'>
        <div className="content">
          <div className='jobCont'>
            <p style={{fontWeight:'bold'}}>Jobs Applied</p>
            <p>Saved Jobs</p>
          </div>
          <div className='jobDetailsCont'>
            <div className='jobName'>
                <h3>Sales Manager</h3>
                <p>Applied: 12 days(s) ago</p>
            </div>
            <div className='jobPlace'>
                <p>Mariott Group</p>
                <span>Los Angeles, CA</span>
            </div>
            <div className='jobDetail'>
                <p>Shipt is a marketplace that provides fresh produce, household essentials, and more from trusted local stores...</p>
                <button>Read More</button>
            </div>
            <div></div>
          </div>
          <div className='jobDetailsCont'>
            <div className='jobName'>
                <h3>Sales Manager</h3>
                <p>Applied: 12 days(s) ago</p>
            </div>
            <div className='jobPlace'>
                <p>Mariott Group</p>
                <span>Los Angeles, CA</span>
            </div>
            <div className='jobDetail'>
                <p>Shipt is a marketplace that provides fresh produce, household essentials, and more from trusted local stores...</p>
                <button>Read More</button>
            </div>
            <div></div>
          </div>
          <div className='jobDetailsCont'>
            <div className='jobName'>
                <h3>Sales Manager</h3>
                <p>Applied: 12 days(s) ago</p>
            </div>
            <div className='jobPlace'>
                <p>Mariott Group</p>
                <span>Los Angeles, CA</span>
            </div>
            <div className='jobDetail'>
                <p>Shipt is a marketplace that provides fresh produce, household essentials, and more from trusted local stores...</p>
                <button>Read More</button>
            </div>
            <div></div>
          </div>
          <div className='jobDetailsCont'>
            <div className='jobName'>
                <h3>Sales Manager</h3>
                <p>Applied: 12 days(s) ago</p>
            </div>
            <div className='jobPlace'>
                <p>Mariott Group</p>
                <span>Los Angeles, CA</span>
            </div>
            <div className='jobDetail'>
                <p>Shipt is a marketplace that provides fresh produce, household essentials, and more from trusted local stores...</p>
                <button>Read More</button>
            </div>
            <div></div>
          </div>
        </div>
      </div>

    </div>    
  )
}

export default userHomeJob
