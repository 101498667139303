import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { BsBookmarkDash } from 'react-icons/bs';
import { BsBookmarkDashFill } from "react-icons/bs";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { bookMark, Configuration, getDetails, jobList } from '../Api/Url';
import Select from 'react-select';
import ExperienceData from '../components/Experience.json'
import { useSelector } from 'react-redux';

const Jobs = ({ id }) => {
    const config =Configuration();
    const [jobLists, setJobLists] = useState('');
    const [bookMarkCont, setBookMarkCont] = useState('');
    const [selectedJobId, setSelectedJobId] = useState(null);
    const [noDataFound, setNoDataFound] = useState('');
    const [searchJob, setSearchJob] = useState(''); 
    const [imageLoaded, setImageLoaded] = useState(false);  
    const [loading, setLoading] = useState(true);
    const [selectExp, setSelectExp] = useState(null);
    const [selectedExperience, setSelectedExperience] = useState(null);
    const jobSearch = useSelector((state) => state.searchData.jobSearch);

    useEffect(()=>{
        if(jobSearch){
            setSearchJob(jobSearch)
        }
    }, [jobSearch])

    const experienceOptions = ExperienceData.data.map((exp) => ({
        value: exp.id,
        label: exp.Experience,
    }));
    
    useEffect(() => {
        const timer = setTimeout(() => {
          setImageLoaded(true);
          setLoading(false);
        }, 2500);
    
        return () => clearTimeout(timer); 
      }, []);

    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const teamId = queryParams.get('team_id');
    
        if (teamId) {
            
            axios.get(`${jobList()}?team_id=${teamId}`, config)
                .then(res => {
                    if (res.data.data.values && res.data.data.values.length > 0) {
                        setJobLists(res.data.data.values); 
                        setLoading(false);
                    } else {
                        setNoDataFound("jobs not found");
                        setLoading(false);
                    }
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                });
        } else {

            const params = {};
            if(searchJob){
                params.search = searchJob
            }
            if(selectedExperience){
                params.experience = selectExp
            }
            config.params = params;
            
            axios.get(jobList(), config)  //setJobLists
                .then(res => {
                    setJobLists(res.data.data.values)
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false);
                });
        }
    }, [location.search, searchJob, selectExp]);  

    const applyJobNav = useNavigate();

    // const handleAllowUser = (id) => {
    //     axios.get(getDetails(), config)
    //         .then(res => {
                
    //             if (res.data.data.user_detail == null || res.data.data.get_user_job_specify_detail == null) {
    //                 allowUserNav('/userapplication');
    //             } else {
    //                 applyJobNav(`/jobdetails?job_id=${id}`);
    //             }
    //         })
    //         .catch(err => console.error('Error fetching details:', err));
    // }

    const [loginId, setLoginId] = useState('');
    // const nav = useNavigate()

    // useEffect(()=>{
    //     let url = window.location.pathname;
    //     if(loginId){
    //         if(loginId == undefined){
    //             window.location.reload();
    //         }
    //         else{
    //             nav(`/u/${loginId}${url}`)
    //             window.location.reload();
    //         }
    //     }
    // })

    
    useEffect(() => {
      const globalValue = window.globalValue;
      setLoginId(globalValue);
    }, [window.globalValue]);

    const handleAllowUser = (id)=>{
        if(loginId){
            applyJobNav(`/u/${loginId}/jobdetails?job_id=${id}`);
        }
        else{
            applyJobNav(`/jobdetails?job_id=${id}`);
        }
    }
    

    const handleIconClick = (id) => {
        let data = {
            "job_id": id
        };

        axios.post(bookMark(), data, config)
            .then(res => {
                setBookMarkCont(res.data.message);
                setSelectedJobId(id); 

                setTimeout(() => {
                    setBookMarkCont('');
                    setSelectedJobId(null); 
                }, 1000);
            })
            .then(() => {
                axios.get(jobList(), config)
                    .then(res => setJobLists(res.data.data.values))
                    .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
    };  

    const handleSelectChange = (selectedOption) => {
        setSelectedExperience(selectedOption);
        setSelectExp(selectedOption.value);
      };

    return (
        <>
            <section className="page-title style-two mt70 bg-fafbfc">
                <div className="auto-container">
                    <div className="job-search-form">
                        <div className="row">
                            <div className="form-group col-lg-3 col-md-3 col-sm-12 border-top border-bottom border-left white-bg"> <span className="icon flaticon-search-1"></span>
                                <input className='pb-3' type="text" name="field_name" placeholder="Job Search" value={searchJob} onChange={(e)=>{setSearchJob(e.target.value)}}/>
                            </div>
                            <div className="form-group col-lg-4 col-md-4 col-sm-12 location border-top border-bottom border-right"> <span className="icon flaticon-map-locator"></span>
                                <input className='pb-3' type="text" name="field_name" placeholder="Location" />
                                <span className='remote-input-box'><input type='checkbox'/> <label>Remote Eligible</label></span>
                            </div>
                            <div className="form-group col-lg-5 col-md-5 col-sm-12 display-flex pr-0 location pl-0">
                                <span className="icon flaticon-briefcase"></span>
                                {/* <select className="chosen-select border-top border-bottom h40 pl50">
                                    <option value="">Experience</option>
                                </select> */}
                                <Select
                                    options={experienceOptions}
                                    value={selectedExperience}
                                    onChange={handleSelectChange}
                                    placeholder="Select Experience"
                                    className="chosen-select border-top border-bottom h40 pl50 extraClass"
                                />
                                {/* <a href='jobsfilter' className="theme-btn-find btn-find-job">Find Jobs</a> */}
                                <NavLink className="theme-btn-find btn-find-job" to={loginId !== null ? `/u/${loginId}/jobsfilter` : '/jobsfilter'} >
                                    Find Jobs
                                </NavLink>
                            </div>
                            {/* <div className="form-group col-lg-1 col-md-2 col-sm-12 text-right pl-0">
                                <a href='jobsfilter' className="theme-btn btn-find-job">Find Jobs</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            <section className="job-categories bg-fafbfc pt-0">
                <div className="auto-container">
                    <div className="sec-sm-title">
                        <h3>Recommended Jobs</h3>
                    </div>
                    <div className='jobs-inner-box white-bg'>
                        <div className="row wow fadeInUp">

                        {loading ? (
                            Array.from({ length: 9 }).map((_, index) => (
                                <div key={index} className="news-block col-lg-4 col-md-6 col-sm-12">
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <figure className="image loading-imageJob"></figure>
                                        </div>
                                        <div className="lower-content space-between display-flex">
                                            <div className="loading-text">
                                                <div className="animated-bg" style={{ height: '20px', width: '60%' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : jobLists.length > 0 ? (
                            jobLists.map((value) => (
                                <div key={value.id} className="col-xl-4 col-sm-6">
                                    <div className="job-block at-jlv17 at-home20 at-home18">
                                        <div className="inner-box">
                                            <div className="tags" >
                                                <div
                                                    className="bookMark"
                                                    onClick={() => handleIconClick(value.id)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    {value.is_bookmark === 0 ? (
                                                        <BsBookmarkDash className="flaticon-bookmark" />
                                                    ) : (
                                                        <BsBookmarkDashFill className="flaticon-bookmark" />
                                                    )}
                                                    {selectedJobId === value.id && <p className="bookMarkCont">{bookMarkCont}</p>}
                                                </div>
                                            </div>
                                            <div className="content ps-0">
                                                <div className="d-flex mb-3 mb-md-0 align-top">
                                                    <span className="position-relative mr15">
                                                        <img className="wa w60" src="/assets/images/icons/ev-logo-icon.png" draggable="false" alt="" />
                                                    </span>
                                                    <div className="text-start">
                                                        <h4 className="fz22 mb-2">
                                                            <a onClick={() => handleAllowUser(value.id)}>{value.name}</a>
                                                        </h4>
                                                        <h6 className="font12">{value.company_detail.name}</h6>
                                                        <ul className="job-other-info at-jsv6 at-jsv17 ms-0">
                                                            <li className="time2 fz12 border-0 ps-0 me-0 mb-0 bg-none">
                                                                <img className="mr10" src="assets/images/icons/location.png" draggable="false" alt="" />
                                                                {value.company_detail.location}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <p className="text">{value.description}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <ul className="job-other-info at-jsv6 at-jsv17 mt15 ms-0 justify-content-md-between">
                                                            <li className="time2 border-0 me-0 light-y-bg fz12">
                                                                <img className="mr10 w-16" src="/assets/images/icons/dark-clock.svg" draggable="false" alt="" />
                                                                Full Time
                                                                {/* {value.vacancy_employment_type[0].employment_type.name} */}
                                                            </li>
                                                            <li className="time2 border-0 light-blue-bg fz12">
                                                                <img className="mr10 w-16" src="/assets/images/icons/dark-briefcase.svg" draggable="false" alt="" />
                                                                Remote
                                                            </li>
                                                            <li className="time2 border-0 bg-D3EFD8 fz12">
                                                                <img className="mr10 w-16" src="/assets/images/icons/dark-activity-notification-square.svg" draggable="false" alt="" />
                                                                {value.experience} Year
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="row mt10">
                                                    <div className="col-lg-12">
                                                        <span className="mr15 fz14 fw-600">3 Days ago</span>
                                                        <span>
                                                            <i className="fa fa-circle fz10 mr10" aria-hidden="true"></i>
                                                        </span>
                                                        <span className="text-blue fz14 fw-600">124 Applicant</span>
                                                    </div>
                                                </div>
                                                <div className="align-items-center justify-content-md-between mt10">
                                                    <div className="align-items-center mb-3 mb-md-0">
                                                        {/* <a
                                                            onClick={() => handleAllowUser(value.id)}
                                                            className="theme-btn btn-style-two w-100"
                                                            disabled={value.is_applied === 1}
                                                        >
                                                            {value.is_applied === 1 ? 'Application Submitted' : 'Apply Now'}
                                                        </a> */}
                                                        <a
                                                            onClick={value.is_applied !== 1 ? () => handleAllowUser(value.id) : null}
                                                            className={`theme-btn btn-style-two w-100 ${value.is_applied === 1 ? 'disabled' : ''}`}
                                                            style={{
                                                                pointerEvents: value.is_applied === 1 ? 'none' : 'auto',
                                                                opacity: value.is_applied === 1 ? 0.6 : 1,
                                                                backgroundColor: value.is_applied === 1 ? 'gray' : '',  // Set the background color when disabled
                                                            }}
                                                            >
                                                            {value.is_applied === 1 ? 'Application Submitted' : 'Apply Now'}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            // <div>{noDataFound}</div>
                            <div><h5>No Jobs Found</h5></div>
                        )}

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Jobs;