import React, { useState } from 'react'
import { CiBasketball, CiMail } from "react-icons/ci";
import { FcGoogle } from "react-icons/fc";
import { PiCrownCrossFill } from "react-icons/pi";
import { FaFacebookF, FaPaypal } from "react-icons/fa6";
import { GiCheckedShield } from "react-icons/gi";
import { BsDot } from "react-icons/bs";
import { MdHeadsetMic } from "react-icons/md";
import { FaTwitter, FaLaptop} from "react-icons/fa";
import { MdCastForEducation, MdOutlineModeEdit } from "react-icons/md";
import { IoClose, IoAddSharp, IoDiamond } from "react-icons/io5";
import { GoArrowLeft } from "react-icons/go";
import { useLocation } from 'react-router-dom';
import Select from 'react-select';

const Resume = ({selectedMonths, selectedYears }) => {

    // bio popup
    const [isBioPopupOpen, setIsBioPopupOpen] = useState(false);
    const openBioPopup = () => setIsBioPopupOpen(true);
    const closeBioPopup = () => setIsBioPopupOpen(false);

    // education edit popup
    const [isEducationEdit, setIsEducationEdit] = useState(false);
    const openEducationEdit = () => setIsEducationEdit(true);
    const closeEducationEdit = () => setIsEducationEdit(false);

    // education add popup
    const [isEducationAdd, setIsEducationAdd] = useState(false);
    const openEducationAdd = () => setIsEducationAdd(true);
    const closeEducationAdd = () => setIsEducationAdd(false);

    // education popup editicon
    const [isPopupEducationEdit, setIsPopupEducationEdit] = useState(false);
    const openPopupEducationEdit = () => setIsPopupEducationEdit(true);
    const closePopupEducationEdit = () => setIsPopupEducationEdit(false);

    // salary edit icon
    const [isSalary, setIsSalary] = useState(false);
    const openSalaryPopup = () => setIsSalary(true);
    const closeSalaryPopup = () => setIsSalary(false);

    // experience add popup
    const [isExperienceAdd, setIsExperienceAdd] = useState(false);
    const openExperienceAdd = () => setIsExperienceAdd(true);
    const closeExperienceAdd = () => setIsExperienceAdd(false);

    // experience edit popup
    const [isExperienceEdit, setIsExperienceEdit] = useState(false);
    const openExperienceEdit = () => setIsExperienceEdit(true);
    const closeExperienceEdit = () => setIsExperienceEdit(false);

    // experience popup editicon
    const [isPopupExperienceEdit, setIsPopupExperienceEdit] = useState(false);
    const openPopupExperienceEdit = () => setIsPopupExperienceEdit(true);
    const closePopupExperienceEdit = () => setIsPopupExperienceEdit(false);

    const location = useLocation();

    //month
    const [selectedMonth, setSelectedMonth] = useState(selectedMonths ? parseInt(selectedMonths, 10) : null);

    const handleMonthChange = (selectedOption) => {
        setSelectedMonth(selectedOption.value);
    };

    const months = [
        "January", "February", "March", "April",
        "May", "June", "July", "August",
        "September", "October", "November", "December"
    ];
    
    const monthOptions = months.map((month, index) => ({
        value: index + 1,
        label: month
    }));

    const customStyles1 = (isSelected) => ({
        control: (provided) => ({
            ...provided,
            borderColor: isSelected ? 'blue' : 'grey',
        }),
        // other styles as needed
    });
    
    //   year
    const currentYear = new Date().getFullYear();
    const startYear = 1900;
    const years = [];
  
    for (let year = currentYear; year >= startYear; year--) {
      years.push(year);
    }
  
    const yearOptions = years.map((year) => ({
      value: year,
      label: year.toString()
    }));

    const [selectedYear, setSelectedYear] = useState(selectedYears ? parseInt(selectedYears, 10) : null);

    const handleYearChange = (selectedOption) => {
        setSelectedYear(selectedOption.value);
      };

  return (
    <div className='auto-container Container'>
        <div className='resumeBg'>
            <div className='row'>
            <div className='headerFixed'>
                <div>
                    <div className='row resumeContainer'>
                        <div className='col-7 col-sm-7 col-md-7 col-lg-7 resumeProfile'>
                            <div className='resumeImg'>
                                <img src="/assets/images/msg1.png" alt="userImg"/>
                            </div>
                            <div className='resumeProfileName'>
                                <h5>Praskovya Dubinina</h5>
                                <div className='resumeProfileCont'>
                                    <div className='googleIconCont'>
                                        <FcGoogle className='googleIcon'/>
                                        <p>Ellassen Group Inc.</p>
                                    </div>
                                    <li>UI/UX Designer</li>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className='row iconsCont'>
                        <div className='col-7 col-sm-7 col-md-7 col-lg-7 iconWithCont'>
                            {/* <div className='iconsWithCont soloIconWithCont'> */}
                            <div className={location.hash === '#about' || location.hash === '' ? 'soloIconWithCont iconsWithCont' : 'iconsWithCont'}>
                                <PiCrownCrossFill />
                                <a href='#about'>About</a>
                            </div>
                            <div className={location.hash === '#experience' ? 'soloIconWithCont iconsWithCont' : 'iconsWithCont'}>
                                <FaLaptop />
                                <a href='#experience'>Work Experience</a>
                            </div>
                            <div className={location.hash === '#education' ? 'soloIconWithCont iconsWithCont' : 'iconsWithCont'}>
                                <MdCastForEducation />
                                <a href='#education'>Education</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row resumeContent'>
                <div id='experience' className='col-7 col-sm-7 col-md-7 col-lg-7'>
                    <div id='about' className='shortBio'>
                        <div className='bioEdit'>
                            <h5>Short bio</h5>
                            <MdOutlineModeEdit className='editIcon' onClick={openBioPopup}/>
                        </div>
                        <p>Once your resume is on Indeed. You can choose to make it  "Public" or "Private". There are benefits to both options. When you make your resume public, it is visible to anyone. Visitors to a public resume page can forward, save or download the resume as a PDF or email you through a secure contact from.</p>
                        <div className='techs'>
                            <div>
                                <p>Business</p>
                                <p>Marketing</p>
                                <p>Development</p>
                                <p>Founder</p>
                                <p>Html </p>
                            </div>
                            <div>
                                <p>Interface Design</p>
                                <p>University</p>
                                <p>Entrepreneur</p>
                            </div>
                        </div>
                    </div>
                    {isBioPopupOpen && (
                            <div className="resumeBioPopup">
                                <div className="resumeBioPopupCont">
                                    <div className='resumeEditIcon'>
                                        <h4>Edit intro</h4>
                                        <IoClose className='popupEditIcon' onClick={closeBioPopup}/>
                                    </div>
                                    <div>
                                        <div className='popupHeadline'>
                                            <p>Headline*</p>
                                            <textarea id="w3review" name="w3review" rows="2" cols="100"></textarea>
                                        </div>
                                        <div className='popupHeadlineSkill'>
                                            <p className='skillPara'>Skill*</p>
                                            <input type="text" placeholder='Skill (ex: Project Management)' className='skill'/>
                                            <div className='popupTechDatasCont'>
                                                <div className='popupSuggestProfile'>
                                                    <h6>Suggested based on your profile</h6>
                                                    <IoClose className='popupEditIcon'/>
                                                </div>
                                                <div className='popupTechDatas'>
                                                    <p>Data visualization</p>
                                                    <p>Customer Satisfaction</p>
                                                    <p>Audition</p>
                                                    <p>Data Analytics</p>
                                                    <p>Data Collections</p>
                                                    <p>Relationship Building</p>
                                                    <p>Documentation</p>
                                                    <p>Databases</p>
                                                    <p>Customer Support</p>
                                                    <p>Data Entry</p>
                                                </div>
                                            </div>
                                            <div className='popupSubmitBtn'>
                                                <input type="button" value='Save'/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        )}
                    <div className='workExperience'>
                        <div className='experienceEdit'>
                            <h5>Work Experience</h5>
                            <div className='experienceEditIcons'>
                                <IoAddSharp className='experiIcons' onClick={openExperienceAdd}/>
                                <MdOutlineModeEdit className='experiIcons' onClick={openExperienceEdit}/>
                            </div>
                        </div>
                        <div className='workExperienceCont'>
                            <div className='workExperienceBallIcon'>
                                <CiBasketball />
                            </div>
                            <div>
                                <div className='workExperienceLocation'>
                                    <div className='workExperienceLocationCont'>
                                        <h6>Crisis Intervention Specialist</h6>
                                        <div className='workExperienceLocationWithTech'>
                                            <p>London</p> 
                                            <BsDot />
                                            <p>Dribble Inc.</p> 
                                        </div>
                                    </div>
                                    <div className='workExperienceLocationYear'>
                                        <p>2018 - Present</p>
                                    </div>
                                </div>
                                <div className='workExperiencePara'>
                                    <p>Minimum 3 shifts a week Monday - Friday with the ability to work an 8 to 9 hour time each week between the hours of 7 A.M - 7 P.M.</p>
                                </div>
                                <hr />
                            </div>
                        </div>
                        <div className='workExperienceCont'>
                            <div className='workExperienceBallIcon'>
                                <FcGoogle />
                            </div>
                            <div>
                                <div className='workExperienceLocation'>
                                    <div className='workExperienceLocationCont'>
                                        <h6>Virtual Scheduler</h6>
                                        <div className='workExperienceLocationWithTech'>
                                            <p>London</p> 
                                            <BsDot />
                                            <p>Google Inc.</p> 
                                        </div> 
                                    </div>
                                    <div className='workExperienceLocationYear'>
                                        <p>2001 - 2018</p>
                                    </div>
                                </div>
                                <div className='workExperiencePara'>
                                    <p>Lines for Life also offers a great benefits package valued at over $9,500 that includes ful coverage for employee health, dental, vision, short.</p>
                                </div>
                                <hr />
                            </div>
                        </div>
                        <div className='workExperienceCont'>
                            <div className='workExperienceFaceBookIcon'>
                                <FaFacebookF />
                            </div>
                            <div>
                                <div className='workExperienceLocation'>
                                    <div className='workExperienceLocationCont'>
                                        <h6>Patient Care Advocate</h6>
                                        <div className='workExperienceLocationWithTech'>
                                            <p>London</p> 
                                            <BsDot />
                                            <p>Facebook Inc.</p> 
                                        </div> 
                                    </div>
                                    <div className='workExperienceLocationYear'>
                                        <p>1998 - 2018</p>
                                    </div>
                                </div>
                                <div className='workExperiencePara'>
                                    <p>Healthcare interest - become an expert on emerging healthcare programs and excited to speak with providers. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isExperienceEdit && (
                        <div className="resumeBioPopup">
                            <div className="resumeEducationPopupCont">
                                <div>
                                    <div className='eduPopupCont'>
                                        <div className='eduPopup'>
                                            <GoArrowLeft className='popupEducationIcons' onClick={closeExperienceEdit}/>
                                            <h5 style={{fontWeight:600}}>Edit Experience</h5>
                                        </div>
                                        <div>
                                            <IoAddSharp className='popupEducationIcons' onClick={openExperienceAdd}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='popupEducationCont'>
                                    <div className='workExperienceCont'>
                                        <div className='workExperienceBallIcon'>
                                            <CiBasketball />
                                        </div>
                                        <div>
                                            <div className='workExperienceLocation'>
                                                <div className='workExperienceLocationCont'>
                                                    <h6>Crisis Intervention Specialist</h6>
                                                    <div className='workExperienceLocationWithTech'>
                                                        <p>London</p> 
                                                        <BsDot />
                                                        <p>Dribble Inc.</p>  
                                                    </div>
                                                </div>
                                                <div className='workExperienceLocationYear'>
                                                    <p>2018 - Present</p>
                                                </div>
                                            </div>
                                            <div className='workExperiencePara'>
                                                <p>Minimum 3 shifts a week Monday - Friday with the ability to work an 8 to 9 hour time each week between the hours of 7 A.M - 7 P.M.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <MdOutlineModeEdit className='popupEducationIcons' onClick={openPopupExperienceEdit}/>
                                    </div>
                                </div>
                                <div className='popupEducationCont'>
                                    <div className='workExperienceCont'>
                                        <div className='workExperienceBallIcon'>
                                            <FcGoogle />
                                        </div>
                                        <div>
                                            <div className='workExperienceLocation'>
                                                <div className='workExperienceLocationCont'>
                                                    <h6>Virtual Scheduler</h6>
                                                    <div className='workExperienceLocationWithTech'>
                                                        <p>London</p> 
                                                        <BsDot />
                                                        <p>Google Inc.</p>  
                                                    </div>
                                                </div>
                                                <div className='workExperienceLocationYear'>
                                                    <p>2001 - 2018</p>
                                                </div>
                                            </div>
                                            <div className='workExperiencePara'>
                                                <p>Lines for Life also offers a great benefits package valued at over $9,500 that includes ful coverage for employee health, dental, vision, short.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <MdOutlineModeEdit className='popupEducationIcons' onClick={openPopupExperienceEdit}/>
                                    </div>
                                </div>
                                <div className='popupEducationCont'>
                                    <div className='workExperienceCont'>
                                        <div className='workExperienceBallIcon'>
                                            <FaFacebookF />
                                        </div>
                                        <div>
                                            <div className='workExperienceLocation'>
                                                <div className='workExperienceLocationCont'>
                                                    <h6>Harvard University</h6>
                                                    <div className='workExperienceLocationWithTech'>
                                                        <p>London</p> 
                                                        <BsDot />
                                                        <p>Facebook Inc.</p> 
                                                    </div>
                                                </div>
                                                <div className='workExperienceLocationYear'>
                                                    <p>1998 - 2018</p>
                                                </div>
                                            </div>
                                            <div className='workExperiencePara'>
                                                <p>Healthcare interest - become an expert on emerging healthcare programs and excited to speak with providers. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <MdOutlineModeEdit className='popupEducationIcons' onClick={openPopupExperienceEdit}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {isPopupExperienceEdit && (
                        <div className="resumeBioPopup">
                            <div className="resumeEduPopupCont">
                                <div>
                                    <div className='resumeEducationEditIcon'>
                                        <h5>Edit Experience</h5>
                                        <IoClose className='popupEducationIcons' onClick={closePopupExperienceEdit}/>
                                    </div>
                                    <div className='popupEducationEdit'>
                                        <div>
                                            <p className='popupEducationCont'>Company*</p>
                                            <input type="text" className='EducationInput' placeholder='Harvard University'/>
                                        </div>
                                        <div style={{marginBottom:'-.9rem'}}>
                                            <p className='popupEducationCont'>About</p>
                                            <textarea className='EducationInput' rows={3} cols={10}>Healthcare Interest - became an expert on emerging healthcare programs and excited to speak with providers about the future of healthcare.</textarea>
                                            {/* <input type="text" className='EducationInput' placeholder="Ex: Bachelor's"/> */}
                                        </div>
                                        <div className='EducationInputCont'>
                                            <div className='EducationInputConts'>
                                                <p className='popupEducationCont'>Location</p>
                                                <input className='EducationInput' type="text" placeholder='London'/>
                                            </div>
                                            <div className='EducationInputConts'>
                                                <p className='popupEducationCont'>Development</p>
                                                <input className='EducationInput' type="text" placeholder='Business Development'/>
                                            </div>
                                        </div>
                                        <div className='EducationInputCont'>
                                            <div className='EducationInputConts'>
                                                <p className='popupEducationCont'>Start Year</p>
                                                {/* <input className='EducationInput' type="text" placeholder='1994'/> */}
                                                <Select id="yearSelect" value={yearOptions.find(option => option.value === selectedYear)} onChange={handleYearChange} options={yearOptions} styles={customStyles1(Boolean(selectedYear))} placeholder="1994" />
                                            </div>
                                            <div className='EducationInputConts'>
                                                <p className='popupEducationCont'>End Year(or expected)</p>
                                                {/* <input className='EducationInput' type="text" placeholder='1998'/> */}
                                                <Select id="yearSelect" value={yearOptions.find(option => option.value === selectedYear)} onChange={handleYearChange} options={yearOptions} styles={customStyles1(Boolean(selectedYear))} placeholder="1998" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='popupSubmitBtn'>
                                    <input type="button" value='Save'/>
                                </div>
                            </div>
                        </div>
                    )}
                    {isExperienceAdd && (
                        <div className="resumeBioPopup">
                            <div className="resumeExperiPopupCont">
                                <div>
                                    <div className='resumeExpEditIcon'>
                                        <h5>Add Experience</h5>
                                        <IoClose className='popupEducationIcons' onClick={closeExperienceAdd}/>
                                    </div>
                                    <div className='AddExperiPopupCont'>
                                        <div>
                                            <p className='popupEducationCont'>Title*</p>
                                            <input type="text" className='EducationInput' placeholder='Ex: Retail Sales Manager'/>
                                        </div>
                                        <div>
                                            <p className='popupEducationCont'>Company name*</p>
                                            <input type="text" className='EducationInput' placeholder="Ex: Microsoft"/>
                                        </div>
                                        <div>
                                            <p className='popupEducationCont'>Location</p>
                                            <input type="text" className='EducationInput' placeholder='Ex: London, United Kingdom'/>
                                        </div>
                                        <div className='resumeEducationLabel'>
                                            <input type="checkbox"/>
                                            <label> I am currently working in this role</label>
                                        </div>
                                        <div>
                                            <p className='popupEducationCont'>Start Date</p>
                                            <div className='EducationInputCont'>
                                                <div className='EducationInputConts'>
                                                    <input className='EducationInput' type="text" placeholder='Month'/>
                                                </div>
                                                <div className='EducationInputConts'>
                                                    <input className='EducationInput' type="text" placeholder='Year'/>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <p className='popupEducationCont'>End Date(or expected)</p>
                                            <div className='EducationInputCont'>
                                                <div className='EducationInputConts'>
                                                    <Select
                                                        id="monthSelect"
                                                        value={monthOptions.find(option => option.value === selectedMonth)}
                                                        onChange={handleMonthChange}
                                                        options={monthOptions}
                                                        styles={customStyles1(Boolean(selectedMonth))}
                                                        placeholder="Select Month"
                                                    />
                                                </div>
                                                <div className='EducationInputConts'>
                                                    <Select id="yearSelect" value={yearOptions.find(option => option.value === selectedYear)} onChange={handleYearChange} options={yearOptions} styles={customStyles1(Boolean(selectedYear))} placeholder="Select Year" />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <p className='popupEducationCont'>Description</p>
                                            <textarea className='EducationInput' rows={3} cols={10}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className='popupSubmitBtn'>
                                    <input type="button" value='Save'/>
                                </div>
                            </div>
                        </div>
                    )}
                    <div id='education' className='workExperience'>
                        <div className='experienceEdit'>
                            <h5>Education</h5>
                            <div className='experienceEditIcons'>
                                <IoAddSharp className='experiIcons' onClick={openEducationAdd}/>
                                <MdOutlineModeEdit className='experiIcons' onClick={openEducationEdit}/>
                            </div>
                        </div>
                        {isEducationEdit && (
                            <div className="resumeBioPopup">
                                <div className="resumeEducationPopupCont">
                                    <div>
                                        <div className='eduPopupCont'>
                                            <div className='eduPopup'>
                                                <GoArrowLeft className='popupEducationIcons' onClick={closeEducationEdit}/>
                                                <h5>Education</h5>
                                            </div>
                                            <div>
                                                <IoAddSharp className='popupEducationIcons' onClick={openEducationAdd}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='popupEducationCont'>
                                        <div className='workExperienceCont'>
                                            <div className='workExperienceBallIcon'>
                                                <GiCheckedShield />
                                            </div>
                                            <div>
                                                <div className='workExperienceLocation'>
                                                    <div className='workExperienceLocationCont'>
                                                        <h6>Harvard University</h6>
                                                        <div className='workExperienceLocationWithTech'>
                                                            <p>London</p> 
                                                            <BsDot />
                                                            <p>Business Development</p> 
                                                        </div>
                                                    </div>
                                                    <div className='workExperienceLocationYear'>
                                                        <p>1994 - 1998</p>
                                                    </div>
                                                </div>
                                                <div className='workExperiencePara'>
                                                    <p>Healthcare Interest - became an expert on emerging healthcare programs and excited to speak with providers about the future of healthcare.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <MdOutlineModeEdit className='popupEducationIcons' onClick={openPopupEducationEdit}/>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        )}
                        {isPopupEducationEdit && (
                            <div className="resumeBioPopup">
                                <div className="resumeEduPopupCont">
                                    <div>
                                        <div className='resumeEducationEditIcon'>
                                            <h5>Edit Education</h5>
                                            <IoClose className='popupEducationIcons' onClick={closePopupEducationEdit}/>
                                        </div>
                                        <div className='popupEducationEdit'>
                                            <div>
                                                <p className='popupEducationCont'>School*</p>
                                                <input type="text" className='EducationInput' placeholder='Harvard University'/>
                                            </div>
                                            <div style={{marginBottom:'-.9rem'}}>
                                                <p className='popupEducationCont'>About</p>
                                                <textarea className='EducationInput' rows={3} cols={10}>Healthcare Interest - became an expert on emerging healthcare programs and excited to speak with providers about the future of healthcare.</textarea>
                                                {/* <input type="text" className='EducationInput' placeholder="Ex: Bachelor's"/> */}
                                            </div>
                                            <div className='EducationInputCont'>
                                                <div className='EducationInputConts'>
                                                    <p className='popupEducationCont'>Location</p>
                                                    <input className='EducationInput' type="text" placeholder='London'/>
                                                </div>
                                                <div className='EducationInputConts'>
                                                    <p className='popupEducationCont'>Course</p>
                                                    <input className='EducationInput' type="text" placeholder='Business Development'/>
                                                </div>
                                            </div>
                                            <div className='EducationInputCont'>
                                                <div className='EducationInputConts'>
                                                    <p className='popupEducationCont'>Start Year</p>
                                                    {/* <input className='EducationInput' type="text" placeholder='1994'/> */}
                                                    <Select id="yearSelect" value={yearOptions.find(option => option.value === selectedYear)} onChange={handleYearChange} options={yearOptions} styles={customStyles1(Boolean(selectedYear))} placeholder="1994" />
                                                </div>
                                                <div className='EducationInputConts'>
                                                    <p className='popupEducationCont'>End Year(or expected)</p>
                                                    {/* <input className='EducationInput' type="text" placeholder='1998'/> */}
                                                    <Select id="yearSelect" value={yearOptions.find(option => option.value === selectedYear)} onChange={handleYearChange} options={yearOptions} styles={customStyles1(Boolean(selectedYear))} placeholder="1998" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='popupSubmitBtn'>
                                        <input type="button" value='Save'/>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isEducationAdd && (
                            <div className="resumeBioPopup">
                                <div className="resumeEduPopupCont">
                                    <div>
                                        <div className='resumeEducationEditIcon'>
                                            <h5>Add Education</h5>
                                            <IoClose className='popupEducationIcons' onClick={closeEducationAdd}/>
                                        </div>
                                        <div>
                                            <div>
                                                <p className='popupEducationCont'>School*</p>
                                                <input type="text" className='EducationInput' placeholder='Ex: Boston University'/>
                                            </div>
                                            <div>
                                                <p className='popupEducationCont'>Degree</p>
                                                <input type="text" className='EducationInput' placeholder="Ex: Bachelor's"/>
                                            </div>
                                            <div>
                                                <p className='popupEducationCont'>Field of Study</p>
                                                <input type="text" className='EducationInput' placeholder='Ex: Business'/>
                                            </div>
                                            <div>
                                                <p className='popupEducationCont'>Start Date</p>
                                                <div className='EducationInputCont'>
                                                    <div className='EducationInputConts'>
                                                        <input className='EducationInput' type="text" placeholder='Month'/>
                                                    </div>
                                                    <div className='EducationInputConts'>
                                                        <input className='EducationInput' type="text" placeholder='Year'/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <p className='popupEducationCont'>End Date(or expected)</p>
                                                <div className='EducationInputCont'>
                                                    <div className='EducationInputConts'>
                                                        {/* <input className='EducationInput' type="text" placeholder='Month'/> */}
                                                        <Select
                                                            id="monthSelect"
                                                            // className='EducationInput'
                                                            value={monthOptions.find(option => option.value === selectedMonth)}
                                                            onChange={handleMonthChange}
                                                            options={monthOptions}
                                                            styles={customStyles1(Boolean(selectedMonth))}
                                                            placeholder="Select Month"
                                                        />
                                                    </div>
                                                    <div className='EducationInputConts'>
                                                        {/* <input className='EducationInput' type="text" placeholder='Year'/> */}
                                                        <Select id="yearSelect" value={yearOptions.find(option => option.value === selectedYear)} onChange={handleYearChange} options={yearOptions} styles={customStyles1(Boolean(selectedYear))} placeholder="Select Year" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='popupSubmitBtn'>
                                        <input type="button" value='Save'/>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className='workExperienceCont'>
                            <div className='workExperienceBallIcon'>
                                <GiCheckedShield />
                            </div>
                            <div>
                                <div className='workExperienceLocation'>
                                    <div className='workExperienceLocationCont'>
                                        <h6>Harvard University</h6>
                                        <div className='workExperienceLocationWithTech'>
                                            <p>London</p> 
                                            <BsDot />
                                            <p>Business Development</p> 
                                        </div>
                                    </div>
                                    <div className='workExperienceLocationYear'>
                                        <p>1994 - 1998</p>
                                    </div>
                                </div>
                                <div className='workExperiencePara'>
                                    <p>Healthcare Interest - became an expert on emerging healthcare programs and excited to speak with providers about the future of healthcare.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-5 col-sm-5 col-md-5 col-lg-5'>
                    <div className='resumeDetails'>
                        <div className='resumeSalary'>
                            <div className='resumeSalaryCont'>
                                <h5>$35,700 - $37,700</h5>
                                <MdOutlineModeEdit className='popupEducationIcons' onClick={openSalaryPopup}/>
                            </div>
                            <p>Avg. salary</p>
                        </div>
                        <div>
                            <div className='mailWithContact'>
                                <div className='resumeMailIcon'>
                                    <CiMail />
                                </div>
                                <div>
                                    <h6>hoangle@yahoo.com</h6>
                                    <p>Contact Email</p>
                                </div>
                            </div>
                            <div className='mailWithContact'>
                                <div className='resumeMailIcon'>
                                    <MdHeadsetMic />
                                </div>
                                <div>
                                    <h6>+64 456 869 393</h6>
                                    <p>Phone</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isSalary && (
                        <div className="resumeBioPopup">
                            <div className="resumeSalaryPopup">
                                <div className='resumeSalaryPopupCont'>
                                    <h2>Edit Salary</h2>
                                    <IoClose className='popupEducationIcons' onClick={closeSalaryPopup}/>
                                </div>
                                <hr />
                                <div className='EducationInputCont'>
                                    <div className='EducationInputConts'> 
                                        <p className='popupSalaryCont'>Maximum Salary</p>
                                        <input className='EducationInput' type="text" placeholder='maximum salary'/>
                                    </div>
                                    <div className='EducationInputConts'>
                                        <p className='popupSalaryCont'>Minimum Salary</p>
                                        <input className='EducationInput' type="text" placeholder='minimum salary'/>
                                    </div>
                                </div>
                                <div className='popupSubmitBtn'>
                                    <input type="button" value='Save'/>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='resumeDetails'>
                        <div className='resumeSalary'>
                            <h5>References</h5>
                        </div>
                        <div>
                            <div className='mailWithContact'>
                                <div className='resumeProfileImg'>
                                    <img src="/assets/images/msg1.png" alt="" />
                                </div>
                                <div>
                                    <h6>Trashae Hubbard</h6>
                                    <p>Crisis Specialist</p>
                                    <div className='diamondCont'>
                                        <FaTwitter className='twitterIcon' />
                                        <p>Twitter Inc.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='mailWithContact'>
                                <div className='resumeProfileImg'>
                                    <img src="/assets/images/msg2.png" alt="" />
                                </div>
                                <div>
                                    <h6>Xlan Zhou</h6>
                                    <p>Virtual Scheduler</p>
                                    <div className='diamondCont'>
                                        <FaPaypal />
                                        <p>PayPal Inc.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='mailWithContact'>
                                <div className='resumeProfileImg'>
                                    <img src="/assets/images/msg3.png" alt="" />
                                </div>
                                <div>
                                    <h6>Alexa Tenerla</h6>
                                    <p>Patient care Advocate</p>
                                    <div className='diamondCont'>
                                        <IoDiamond className='diamondIcon'/>
                                        <p>Sketch Inc.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='connectionBtn'>
                            <p>See all connections</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Resume
