import { createSlice } from "@reduxjs/toolkit";

const SearchSlice = createSlice({
    name:'search',
    initialState:{
        jobSearch:'',
        location:''
    },
    reducers:{
        updateJobSearch:(state, action)=>{
            state.jobSearch = action.payload
        }
    }
})

export default SearchSlice.reducer
export const {updateJobSearch} = SearchSlice.actions