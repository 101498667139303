import React, { useEffect, useState } from 'react'
import { HiOutlinePencil } from "react-icons/hi2";
import { TbWorld } from "react-icons/tb";
import { TbClockHour3Filled } from "react-icons/tb";
import { useLocation, useNavigate } from 'react-router-dom';

const PersonDetails = ({jobIsApply, jobApplyCount}) => {

  const nav = useNavigate();
  const location = useLocation();
  const pathLocation = (location.pathname.split('/'));
  const locationPath = pathLocation[pathLocation.length - 1];

  const [loginId, setLoginId] = useState('');
    
    useEffect(() => {
      const globalValue = window.globalValue;
      setLoginId(globalValue);
    }, [window.globalValue]);    

    const handleResumeBtn=()=>{
      nav(`/u/${loginId}/resume`)
    }

    const handleMsgBtn=()=>{
      nav(`/u/${loginId}/message`)   
    }

    const handleAppliedJob=()=>{
      nav(`/u/${loginId}/home`)
    }

  return (
    <div>
      <div className="content">
          <div className='profileEdit'>
            <img src="/assets/images/userProfile.png"/>
            <HiOutlinePencil className='profileEditIcon'/>
          </div>
          <div className='userName'>
            <h4>Satvik Brown</h4>
            <p>Los Angeies, CA 90036</p>
          </div>
          <div>
            <div className='userDetails'>
                <p>Most Recent Job</p>
                <h5>Oracle</h5>
            </div>
            <div className='userDetails'>
                <p>Last Qualification</p>
                <h5>B.Tech</h5>
            </div>
            <div className='userDetails'>
                <p>Work Experience</p>
                <h5>5.5 Years</h5>
            </div>
            <div className='usermail'>
                <TbWorld className='UserHomeIcon'/>
                <p>setvik.kk@gmail.com</p>
            </div>
            <div className='usermail'>
                <TbClockHour3Filled className='UserHomeIcon'/>
                <p>0123456789</p>
                <button>Pending</button>
            </div>
            <div className='userResume'>
                <button onClick={handleResumeBtn}>Upload your latest resume</button>
            </div>
          </div>
        </div>
        <div className={locationPath === 'home' || locationPath === '' || locationPath === 'savedJob' ? 'content personDetailActive' : 'content'} onClick={handleAppliedJob}>
            Jobs Applied({jobIsApply === true ? jobApplyCount : 0 })
        </div>
        <div className={locationPath === 'message' ? 'content personDetailActive' : 'content'} onClick={handleMsgBtn}>
            Message
        </div>
    </div>
  )
}

export default PersonDetails
