import React from 'react'
// import { BsDot } from "react-icons/bs";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { TbReload } from "react-icons/tb";
import { FaPlus, FaHouseChimneyWindow } from "react-icons/fa6";
import { BsThreeDotsVertical, BsFillLockFill } from "react-icons/bs";
import { IoStarOutline } from "react-icons/io5";
import { PiDiamondDuotone } from "react-icons/pi";
import { SlCalender, SlSettings } from "react-icons/sl";
import { FiVideo } from "react-icons/fi";
import { BsChatRightDots, BsRecordBtn, BsBell } from "react-icons/bs";
import { TiMediaRecord, TiMediaPause } from "react-icons/ti";
import { RiCheckboxBlankFill } from "react-icons/ri";
import { HiVolumeUp } from "react-icons/hi";
import { IoCall } from "react-icons/io5";
import { IoVideocamOffSharp } from "react-icons/io5";
import { MdMic } from "react-icons/md";
import { RiVoiceprintFill } from "react-icons/ri";
import { CiSettings } from "react-icons/ci";
import { BsFillChatRightDotsFill } from "react-icons/bs";

const Vdo = () => {
  return (
    <div className='auto-container userHomeBg'>
        <div className='vdoBg'>
            <div className='row meetLink'>
                <div className='col-4 col-sm-4 col-md-4 col-lg-4 vdoDotArrow'>
                    <div className='vdoDots'>
                        <p className='vdoDotRed'></p>
                        <p className='vdoDotOrange'></p>
                        <p className='vdoDotGreen'></p>
                    </div>
                    <div className='vdoArrow'>
                        <FiArrowLeft />
                        <FiArrowRight />
                        <TbReload />
                    </div>
                </div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4 inputContainerVdo">
                    <BsFillLockFill className="lockIconVdo" />
                    <input type="text" value="meethub.com/home" readOnly className="inputVdo" />
                    <IoStarOutline  className="starIconVdo" />
                </div>
                <div className='col-4 col-sm-4 col-md-4 col-lg-4 iconsPlusDot'>
                    <FaPlus />
                    <BsThreeDotsVertical />
                </div>
            </div>
            <div className='row'>
                <div className='col-1 col-sm-1 col-md-1 col-lg-1 vdoLeftIcons'>
                    <div>
                        <PiDiamondDuotone className='vdoDia'/>
                    </div>
                    <div className='vdoLeftIconsProfile'>
                        <FaHouseChimneyWindow className='vdoIcons'/>
                        <SlCalender className='vdoIcons'/>
                        <FiVideo className='vdoIcons'/>
                        <SlSettings className='vdoIcons'/>
                        <BsBell className='vdoIcons'/>
                        <BsChatRightDots className='vdoIcons'/>
                    </div>
                    <div className='vdoProfileImg'>
                        <img src="/assets/images/msg1.png" alt="img" />
                    </div>
                </div>
                <div className='col-11 col-sm-11 col-md-11 col-lg-11 vdoScreenBgCont'>
                    <div className='row vdoScreenBg'>
                        <div className='vdorecordCont'>
                            <div className='col-6 col-sm-6 col-md-6 col-lg-6 vdoScreenBgLeftArrowCont'>
                                <FiArrowLeft className='vdoScreenBgLeftArrow'/>
                                <h5>Design Project Initial, 15 Nov 2023</h5>
                            </div>
                            <div className='col-6 col-sm-6 col-md-6 col-lg-6 vdoScreenBgRecordCont'>
                                <p>07:32:34</p>
                                <BsRecordBtn className='vdoScreenBgLeftArrow'/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-7 col-sm-7 col-md-7 col-lg-7 vdoMainBg'>
                                <div className='vdoScreenNameRecord'>
                                    <div className='vdoScreenName'>
                                        <h6>A</h6>
                                        <p>You</p>
                                    </div>
                                    <div className='vdoScreenRecord'>
                                        <TiMediaRecord className='vdoScrennRecordIcon'/>
                                        <p>Record...</p>
                                        <TiMediaPause className='vdoScrennPauseIcon'/>
                                        <RiCheckboxBlankFill className='vdoScrennBoxIcon'/>
                                    </div>
                                </div>
                                <div className='vdoScreenClIcons'>
                                    <HiVolumeUp className='vdoScreenClIcon'/>
                                    <BsFillChatRightDotsFill className='vdoScreenClIconChat'/>
                                    <IoCall className='vdoScreenClIconcl'/>
                                    <IoVideocamOffSharp className='vdoScreenClIcon'/>
                                    <MdMic className='vdoScreenClIcon'/>
                                </div>
                                <div className='vdoScreenVoiceCont'>
                                    <div className='vdoScreenVoice'>
                                        <div>
                                            <RiVoiceprintFill className='vdoVoiceIcon'/>
                                        </div>
                                        <div className='vdoScreenVoiceLine'>
                                            <h6>CC Subtitle</h6>
                                            <p>Hi team, I hope everyone's doing well today. We have an exciting new...</p>
                                        </div>
                                    </div>
                                    <div>
                                        <CiSettings className='vdoVoiceIcon'/>
                                    </div>
                                </div>
                            </div>
                            <div className='col-5 col-sm-5 col-md-5 col-lg-5'>
                                <div className='row'>
                                    <div className='col-6 col-sm-6 col-md-6 col-lg-6'>
                                        <div className='vdoGrpImg'>
                                            <div>
                                                <MdMic className='vdoMute'/>
                                            </div>
                                            <div className='vdoGrpImgCont'>
                                                <p>Ei Maulina</p>
                                            </div>
                                        </div>
                                        <div className='vdoGrpImg'>
                                            <div>
                                                <MdMic className='vdoMute'/>
                                            </div>
                                            <div className='vdoGrpImgCont'>
                                                <p>Ei Maulina</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6 col-sm-6 col-md-6 col-lg-6'>
                                        <div className='vdoGrpImg'>
                                            <div>
                                                <MdMic className='vdoMute'/>
                                            </div>
                                            <div className='vdoGrpImgCont'>
                                                <p>Ei Maulina</p>
                                            </div>
                                        </div>
                                        <div className='vdoGrpImg'>
                                            <div>
                                                <MdMic className='vdoMute'/>
                                            </div>
                                            <div className='vdoGrpImgCont'>
                                                <p>Ei Maulina</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-9 col-sm-9 col-md-9 col-lg-9 meetingCalendar'>saran</div>
                        <div className='col-3 col-sm-3 col-md-3 col-lg-3 meetingCalendar'>saran</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Vdo
